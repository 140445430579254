import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header } from '../modules/ui/Datatable/Header';
import { Column } from 'primereact/column';
import { DataTable } from '../modules/ui/Datatable';
import { FilterMatchMode } from 'primereact/api';
import { format } from 'date-fns';
import { ChangeEvent, memo, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ConversationChecklistAgentProfile from '../components/ConversationChecklist/AgentProfile';
import HighlightReel from '../components/HighlightReel';
import QuickViewModal from '../components/QuickViewModal';
import Star from '../components/Star/Star';
import TrainingModules from '../components/TrainingModules';
import Avatar from '../components/ui/Avatar';
import { useAgentContext } from '../context/AgentProvider';
import { useGetAgentByLogin, useGetEngagements } from '../hooks/queries/MyTeam';
import { engagementLinkBuilder } from '../utils/agent';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';

const AgentProfile = () => {
  const { agentId = '' } = useParams<{ agentId: string }>();
  const { data: agentData = { content: [] } } = useGetAgentByLogin(agentId);
  const { getPermission } = usePermissionContext();

  const agent = agentData.content[0];

  const { data: engagements, isLoading: isEngagementsLoading } =
    useGetEngagements(agent?.agentExternalId);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const { toggleAgentWatchList } = useAgentContext();

  const handleAgentWatchList = useCallback(
    (isWatchListed: boolean, alertMessage: AlertMessageParams) => {
      if (agentId) {
        toggleAgentWatchList(agentId, isWatchListed, alertMessage);
      }
    },
    [agentId, toggleAgentWatchList]
  );

  if (!agent) {
    return (
      <div className="flex flex-col items-center w-full p-20 gap-y-2">
        <FontAwesomeIcon
          className="text-colorGray animate-spin"
          icon={faSpinner}
          size="2x"
        />
        <p>Loading agent information</p>
      </div>
    );
  }

  const hasConversationChecklistAgentProfilePermission = getPermission(
    'se-view_convo_checklist_apv'
  );
  const hasAgentProfilePermissions = getPermission('se-view_agent_profile');
  const hasEngagementTablePermission = getPermission(
    'se-view_engagements_table'
  );

  return hasAgentProfilePermissions ? (
    <div className="container py-7 flex items-baseline justify-between flex-col gap-y-2.5 lg:flex-row lg:items-start lg:gap-7">
      {!!agent && (
        <div className="flex gap-4">
          <div className="w-80 md:w-full p-6 min-w-[240px] max-w-xs drop-shadow-lg bg-white rounded flex flex-col items-center gap-y-4">
            <Avatar
              agentId={agent?.agentExternalId}
              firstName={agent?.firstName}
              key={agent?.agentExternalId}
              lastName={agent?.lastName}
            />
            <div className="flex flex-col items-center">
              <div className="flex justify-center gap-x-2">
                <Star
                  isInWatchList={agent?.watchList}
                  setAgentWatchList={handleAgentWatchList}
                />
                <h3 className="text-sm font-medium text-slate-800">
                  {`${agent?.firstName} ${agent?.lastName}`}
                </h3>
              </div>
              <p className="text-xs font-light text-colorLightGray">
                {agent?.agentExternalId}
              </p>
            </div>
            <div className="mx-auto w-[100%] border-[0.5px] border-colorLightGray"></div>
            <div>
              <h2 className="flex items-start pb-2 text-xs font-medium text-slate-800">
                Agent Details
              </h2>
              <div className="flex flex-row gap-y-2">
                <div>
                  <p className="text-[10px] font-light text-colorLightGray">
                    <span className="min-w-[90px] inline-block">Program: </span>
                    <span className="font-light text-slate-800">
                      {agent?.program?.name || '-'}
                    </span>
                  </p>
                  <p className="text-[10px] font-light text-colorLightGray">
                    <span className="min-w-[90px] inline-block">Manager: </span>
                    <span className="font-light text-slate-800">
                      {agent?.directSupervisor?.fullName || '-'}
                    </span>
                  </p>
                  <p className="text-[10px] font-light text-colorLightGray">
                    <span className="min-w-[90px] inline-block">
                      Department:{' '}
                    </span>
                    <span className="font-light text-slate-800">
                      {agent?.department?.name || '-'}
                    </span>
                  </p>
                  <p className="text-[10px] font-light text-colorLightGray">
                    <span className="min-w-[90px] inline-block">Center: </span>
                    <span className="font-light text-slate-800">
                      {agent?.center?.name || '-'}
                    </span>
                  </p>
                  <p className="text-[10px] font-light text-colorLightGray">
                    <span className="min-w-[90px] inline-block">
                      HiPer Start Date:{' '}
                    </span>
                    <span className="font-light text-slate-800">
                      {(agent?.startDate &&
                        format(new Date(agent?.startDate), 'd MMMM yyyy')) ||
                        '-'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col items-start w-full gap-y-2">
        <div className="flex flex-col w-full gap-4 mb-6">
          <HighlightReel title="Highlight Reel" />
          <TrainingModules title="Training Modules" />
          {hasConversationChecklistAgentProfilePermission && (
            <ConversationChecklistAgentProfile
              agentExternalId={agent.agentExternalId}
              expanded
              title="Conversation Checklist"
            />
          )}
        </div>
        {hasEngagementTablePermission && (
          <>
            <h2 className="w-full pt-2 pb-4 pl-4 text-lg font-bold border-t-[1px] text-colorGray border-[#CED6DE]">
              Engagements
            </h2>
            <div className="w-full p-6 bg-white rounded drop-shadow-lg">
              <DataTable
                alwaysShowPaginator={false}
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                dataKey="engagement_id"
                emptyMessage={
                  isEngagementsLoading
                    ? 'Loading engagements...'
                    : 'No engagements found.'
                }
                filters={{
                  global: {
                    value: globalFilterValue,
                    matchMode: FilterMatchMode.CONTAINS,
                  },
                }}
                globalFilterFields={['engagement_id', 'topic_name']}
                header={
                  <Header
                    globalFilterValue={globalFilterValue}
                    onGlobalFilterChange={(
                      e: ChangeEvent<HTMLInputElement>
                    ) => {
                      const value = e.target.value;
                      setGlobalFilterValue(value);
                    }}
                    placeholderSearch=""
                  />
                }
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50]}
                sortOrder={-1}
                value={engagements}
              >
                <Column
                  align="center"
                  body={EngagementLink}
                  className="agHeadCls"
                  field="engagement_id"
                  header="Engagement ID"
                  style={{ minWidth: '4rem' }}
                />
                <Column
                  align="center"
                  body={FormattedEngagementDate}
                  className="agHeadCls"
                  header="Date"
                  style={{ minWidth: '4rem' }}
                />
                <Column
                  align="center"
                  body={FormattedEngagementTime}
                  className="agHeadCls"
                  header="Time"
                  style={{ minWidth: '4rem' }}
                />
                <Column
                  align="center"
                  body={EngagementTopicName}
                  className="agHeadCls"
                  header="Topic"
                  style={{ minWidth: '4rem' }}
                />
                <Column
                  align="center"
                  className="agHeadCls"
                  header="Quick View"
                  body={({
                    engagement_id,
                    engagement_start_time,
                  }: EngagementDetail) => (
                    <QuickViewModal
                      engagementId={engagement_id}
                      agent={agentData.content[0]}
                      timestamp={engagement_start_time}
                    />
                  )}
                  style={{ minWidth: '4rem' }}
                />
              </DataTable>
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <InvalidPermissions type="page" />
  );
};

function EngagementLink({
  engagement_start_time,
  engagement_id,
}: Readonly<EngagementDetail>) {
  return (
    <Link
      className="text-colorBlue"
      to={engagementLinkBuilder(engagement_start_time, engagement_id)}
    >
      {engagement_id}
    </Link>
  );
}

function FormattedEngagementDate({
  engagement_start_time,
}: Readonly<EngagementDetail>) {
  return <span>{format(new Date(engagement_start_time), 'MMM dd, yyyy')}</span>;
}

function FormattedEngagementTime({
  engagement_start_time,
}: Readonly<EngagementDetail>) {
  return <span>{format(new Date(engagement_start_time), 'hh:mm:ss aa')}</span>;
}

function EngagementTopicName({ topic_name }: Readonly<EngagementDetail>) {
  return <span>{topic_name}</span>;
}

export default memo(AgentProfile);
