import AgentsListSection from '../components/Agents/AgentListSection';
import AgentsTableViewSection from '../components/Agents/AgentsTableViewSection';
import MyTeamContainer from '../components/MyTeam/MyTeamContainer';

import { memo, useState } from 'react';
import { usePermissionContext } from '../context/PermissionsProvider';
import { getSessionStorageOrDefault } from '../utils/misc';

const MyTeam = () => {
  const [myTeamGridView, setMyTeamGridView] = useState<boolean>(
    getSessionStorageOrDefault('myTeamGridView', true)
  );
  const { getPermission } = usePermissionContext();
  const hasViewAgentsPermission = getPermission('se-view_agents');

  return (
    <MyTeamContainer>
      {hasViewAgentsPermission &&
        (myTeamGridView ? (
          <AgentsListSection changeView={setMyTeamGridView} />
        ) : (
          <AgentsTableViewSection changeView={setMyTeamGridView} />
        ))}
    </MyTeamContainer>
  );
};

export default memo(MyTeam);
