import { ReactNode, useRef } from 'react';
import { OverlayPanel, OverlayPanelProps } from 'primereact/overlaypanel';

import styles from './Notification.module.scss';

interface NotificationProps {
  quantity?: number;
  children?: ReactNode;
  emptyMessage?: string;
}

const defaultEmptyMessage = `No notifications yet! You'll be alerted here when you receive a notification.`;

type NotificationComponentProps = NotificationProps & OverlayPanelProps;

const Notifications = ({
  quantity,
  emptyMessage = defaultEmptyMessage,
  children,
  ...props
}: NotificationComponentProps) => {
  const overlayPanelRef = useRef<OverlayPanel>(null);

  return (
    <span>
      <button
        type="button"
        style={{
          position: 'relative',
          cursor: 'pointer',
        }}
        onClick={(e) => overlayPanelRef.current?.toggle(e)}
      >
        {quantity && (
          <span className={styles.notificationQuantitySpan}>{quantity}</span>
        )}
        <i className={'pi pi-bell ' + styles.iconColor} />
      </button>
      <OverlayPanel ref={overlayPanelRef} {...props}>
        <h3 className={styles.notificationH3}>Notifications</h3>
        {children || (
          <div
            style={{ display: 'flex', alignItems: 'center', height: '200px' }}
          >
            <p
              style={{
                textAlign: 'center',
                fontWeight: '300',
                fontSize: '13px',
              }}
            >
              {emptyMessage}
            </p>
          </div>
        )}
      </OverlayPanel>
    </span>
  );
};

export { Notifications };
export type { NotificationProps };
