/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column } from 'primereact/column';
import { ReactNode } from 'react';

interface AgentColumnInterface {
  body?: (rowData: any) => ReactNode;
  field: string;
  filter?: boolean;
  filterElement?: any;
  filterField?: string;
  onFilterClear?: () => void;
  header?: string;
  key?: string;
  showClearButton?: boolean;
  showFilterMenu?: boolean;
  sortable?: boolean;
  filterPlaceholder?: string;
}

const AgentTableColumn = ({
  key,
  field,
  filterElement,
  sortable,
  filter,
  filterField,
  filterPlaceholder,
  onFilterClear,
  showFilterMenu,
  body,
  header,
  showClearButton,
}: AgentColumnInterface) => (
  <Column
    body={body}
    onFilterClear={onFilterClear}
    filterField={filterField}
    filterPlaceholder={filterPlaceholder}
    field={field}
    filterElement={filterElement}
    filter={filter}
    header={header}
    key={key}
    showClearButton={showClearButton}
    showFilterMenu={showFilterMenu}
    sortable={sortable}
    sortField={field}
  />
);

export default AgentTableColumn;
