import { ReactNode, useState } from 'react';

import styles from './WidgetTitle.module.scss';

type WidgetTitleProps = {
  title?: ReactNode;
  infoText?: string;
  children?: ReactNode;
  className?: string;
};

const WidgetTitle = ({
  title,
  infoText,
  children,
  className,
}: WidgetTitleProps) => {
  const [isShowingInfo, setIsShowingInfo] = useState(false);

  return (
    <>
      <div className={styles.titleContainer + className}>
        <h3 className={styles.titleTxt}>{title}</h3>
        {!isShowingInfo && infoText && (
          <>
            <i
              className="pi pi-info-circle"
              onMouseEnter={() => setIsShowingInfo(!!infoText)}
              style={{ color: '#21354c' }}
            />
            {children}
          </>
        )}
      </div>
      {isShowingInfo && (
        <div
          className={styles.titleContainer}
          onMouseLeave={() => setIsShowingInfo(false)}
        >
          <div className={styles.titleContainer + className}>
            <h3 className={styles.titleTxt}>{title}</h3>
            <i className="pi pi-info-circle" />
          </div>
          <p className={styles.infoTxt}>{infoText}</p>
        </div>
      )}
    </>
  );
};

export { WidgetTitle };
