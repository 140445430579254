import { ComponentProps } from 'react';
import { ResponsiveHeatMap } from '@nivo/heatmap';

const HeatMap = ({
  className,
  theme,
  axisLeft,
  axisTop,
  ...heatMapProps
}: ComponentProps<typeof ResponsiveHeatMap> & { className?: string }) => (
  <div className={className} style={{ fontWeight: 600 }}>
    <ResponsiveHeatMap
      margin={{ top: 60, right: 40, bottom: 60, left: 120 }}
      axisLeft={{ tickSize: 10, tickPadding: 5, ...axisLeft }}
      axisTop={{ tickSize: 0, tickPadding: 10, ...axisTop }}
      theme={{
        text: {
          fontFamily: '"Roboto", "Arial", sans-serif;',
          fontSize: 13,
        },
        ...theme,
      }}
      {...heatMapProps}
    />
  </div>
);

export { HeatMap };
