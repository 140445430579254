import { Badge } from 'primereact/badge';
import { clsx } from 'clsx';

export default function AgentBadge({
  agentInitials,
  color,
}: Readonly<{
  agentInitials: string;
  color: string;
}>) {
  return (
    <Badge
      size="large"
      value={agentInitials}
      className={clsx(
        'text-sm items-center justify-center p-2.5 font-normal rounded-full w-10 h-10',
        color
      )}
    />
  );
}
