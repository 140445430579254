import { Button } from 'primereact/button';
import { PropsWithChildren } from 'react';

interface TeamPerformancePanelProps extends PropsWithChildren {
  toggleSort: () => void;
}

export default function TeamPerformancePanel({
  children,
  toggleSort,
}: Readonly<TeamPerformancePanelProps>) {
  return (
    <div className="flex flex-col gap-y-1 h-full px-5 pt-5 w-[266px] min-w-[266px] bg-[#E4E4E4] -ml-px rounded-3xl">
      <div className="flex items-center justify-between text-colorGray">
        <h3 className="text-[16px] whitespace-nowrap font-semibold">
          Team Performance
        </h3>
        <div className="text-sm text-gray-400 p-2 w-[58px] flex justify-around">
          <Button
            icon={<i className="pi pi-sort-alt " />}
            label="Sort"
            onClick={toggleSort}
            pt={{
              root: { className: 'flex flex-row-reverse gap-2' },
              label: { className: 'font-normal' },
            }}
          />
        </div>
      </div>
      <span className="mb-2 text-sm text-colorGray">
        {/* More options to be added later */}
        Overall Usage
      </span>
      <div
        className="flex flex-col gap-y-1 h-full w-[252px] min-w-[252px] -ml-px overflow-y-auto overflow-x-hidden scroll-pr-0"
        style={{ scrollbarColor: '#b4b4b4 #e4e4e4' }}
      >
        <div className="flex flex-col w-[230px]">{children}</div>
      </div>
    </div>
  );
}
