import { useHiperPlayContext } from '../../context/HiperPlayContext';

import styles from '../../hiper-play.module.scss';

const HiperPlayChat = () => {
  const { messageList } = useHiperPlayContext();

  return (
    <div className={styles.hiperPlayChatContainer}>
      {messageList.map(({ content, from }) => (
        <div
          className={
            from === 'Agent'
              ? styles.hiperPlayAgentMsg
              : styles.hiperPlayVisitorMsg
          }
          key={from}
        >
          {content}
        </div>
      ))}
    </div>
  );
};

export { HiperPlayChat };
