import {
  useContext,
  createContext,
  Children,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { useGetCurrentUser } from '../hooks/queries';

// eslint-disable-next-line @typescript-eslint/ban-types
type PermissionsList = 'loginSummary' | (string & {});

type Children = { children: ReactNode };

interface PermissionsProviderProps {
  getPermission(page: string): boolean;
  isUserAuthorized(permission: string): boolean;
  permissions: string[];
}

const PermissionsContext = createContext<PermissionsProviderProps | null>(null);

export const usePermissionContext = () => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error(
      'You must use usePermissionContext within PermissionProvider'
    );
  }

  return context;
};

const PermissionsProviderWrapper = (children: Children) => {
  const [permissions, setPermissions] = useState<string[]>([]);
  const { data: userData } = useGetCurrentUser();

  useEffect(() => {
    const userPermissions = userData?.attributes.platformAuthorities ?? [];
    if (userPermissions.length) {
      setPermissions(userPermissions);
    }
  }, [userData?.attributes.platformAuthorities]);

  const value: PermissionsProviderProps = {
    getPermission: (permission: PermissionsList) =>
      permissions.includes(permission),
    isUserAuthorized: (permission: string) => permissions.includes(permission),
    permissions,
  };

  return <PermissionsContext.Provider {...children} value={value} />;
};

const PermissionsProvider = ({ children }: Children) => (
  <PermissionsProviderWrapper>{children}</PermissionsProviderWrapper>
);

export default PermissionsProvider;
