import {
  HiperChatMessage,
  HiperPlayFormInputs,
  HiperPlayProps,
} from '../types';
import { useHiperPlayContext } from '../context/HiperPlayContext';

import { HiperPlayWelcome } from './HiperPlayWelcome';
import { HiperPlayScenario, HiperPlayChat } from './scenario';

import styles from '../hiper-play.module.scss';

const HiperPlayWidget = ({
  title,
  dropdownFields,
  onStart,
  onEnd,
  onAgentMessage,
  onVisitorMessage,
}: HiperPlayProps) => {
  const { isActive, setIsActive, pushMessage } = useHiperPlayContext();

  const handleStartEngagement = (formData: HiperPlayFormInputs) => {
    onStart(formData, () => setIsActive(true));
  };

  const handleEndEngagement = () => {
    if (onEnd) {
      onEnd(() => setIsActive(false));
    } else {
      setIsActive(false);
    }
  };

  const handleMessage = (message: HiperChatMessage) => {
    if (message.from === 'Agent') {
      onAgentMessage(message, () => pushMessage(message));
    } else {
      onVisitorMessage(message, () => pushMessage(message));
    }
  };

  return (
    <div className={styles.hiperPlayWrapper}>
      <header className={styles.hiperPlayHeader}>
        <span>{title}</span>
        {isActive && <i className="pi pi-info-circle" />}
      </header>
      {isActive ? (
        <>
          <HiperPlayChat />
          <HiperPlayScenario
            endEngagement={handleEndEngagement}
            onMessage={handleMessage}
          />
        </>
      ) : (
        <HiperPlayWelcome
          dropdownFields={dropdownFields}
          startEngagement={handleStartEngagement}
        />
      )}
    </div>
  );
};

export { HiperPlayWidget };
