import styles from './InvalidPermissions.module.scss';

interface PermissionsComponent {
  type: string;
}

const InvalidPermissions = ({ type }: PermissionsComponent) => {
  const str = `Invalid permissions to visit this ${type}.`;
  return (
    <div className={styles.container}>
      <p className={styles.warningBlurb}>{str}</p>
    </div>
  );
};

export default InvalidPermissions;
