import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { api } from '../services/api';

interface Whitelabel {
  companyName: string;
}

const defaultWhitelabel: Whitelabel = {
  companyName: 'XSELL',
};

type Children = { children: ReactNode };
const WhitelabelContext = createContext<Whitelabel>(defaultWhitelabel);

export const useWhitelabel = () => useContext(WhitelabelContext);

export const WhitelabelProvider = (children: Children) => {
  const [currentWhitelabel, setCurrentWhitelabel] =
    useState<Whitelabel>(defaultWhitelabel);

  const getWhitelabel = async () => {
    const response = await api.get<'XSELL' | 'VMO2'>('api/whitelabel');
    if (!response?.data) {
      setCurrentWhitelabel({ companyName: 'XSELL' });
    }
    setCurrentWhitelabel({ companyName: response.data });
    window.sessionStorage.setItem('companyName', response.data);
  };

  useEffect(() => {
    const companyName = window.sessionStorage.getItem('companyName');
    if (companyName) {
      setCurrentWhitelabel({ companyName: companyName });
      return;
    }
    void getWhitelabel();
  }, []);

  return <WhitelabelContext.Provider {...children} value={currentWhitelabel} />;
};
