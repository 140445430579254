import { ChangeEvent } from 'react';
import { InputText } from 'primereact/inputtext';
import styles from './Header.module.scss';

type Props = {
  globalFilterValue: string;
  onGlobalFilterChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholderSearch?: string;
};

const Header = ({
  globalFilterValue,
  onGlobalFilterChange,
  className,
  placeholderSearch = 'Keyword Search',
}: Props) => (
  <div className={`${className} flex justify-content-end`}>
    <span className={styles.pInputIconLeft}>
      <i className="pi pi-search" />
      <InputText
        value={globalFilterValue}
        onChange={onGlobalFilterChange}
        placeholder={placeholderSearch}
      />
    </span>
  </div>
);

export { Header };
