import { api } from './api';
import {
  DEMO_AGENT_EXTERNAL_ID,
  getAgentRecommendationsMock,
  getConversationChecklistForAgentMock,
} from './demo/data';

const SUPEX_BASE_URL = '/supex';
const contact_mode = 'both';

const Routes = {
  ConversationchecklistForAgent:
    SUPEX_BASE_URL + '/conversationchecklistForAgent/',
  ConversationchecklistForEngagement:
    SUPEX_BASE_URL + '/conversationchecklistForEngagement/',
  GetAgentRecommendations: SUPEX_BASE_URL + '/agentRecommendations/',
};

export async function getConversationChecklistForAgent(agent_id: string) {
  if (agent_id === DEMO_AGENT_EXTERNAL_ID) {
    return getConversationChecklistForAgentMock;
  }

  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationchecklistForAgent,
    {
      params: {
        agent_id,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getConversationChecklistForEngagement(
  engagementId: string
) {
  const response = await api.get<ConversationChecklist[]>(
    Routes.ConversationchecklistForEngagement,
    {
      params: {
        engagement_id: engagementId,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getAgentRecommendations(
  agentExternalId: string,
  weekBeginDate: string,
  recType: string
) {
  if (agentExternalId === DEMO_AGENT_EXTERNAL_ID) {
    if (recType === 'Motivation') {
      return getAgentRecommendationsMock;
    }

    return [];
  }

  const response = await api.get<GetAgentRecommendations[]>(
    Routes.GetAgentRecommendations,
    {
      params: {
        week_begin_date: weekBeginDate,
        agent_id: agentExternalId,
        rec_type: recType,
      },
    }
  );

  return response.data;
}
