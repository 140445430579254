import { Dropdown } from 'primereact/dropdown';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { HiperPlayFormInputs, HiperPlayWelcomeProps } from '../types';

import styles from '../hiper-play.module.scss';

const HiperPlayWelcome = ({
  dropdownFields,
  startEngagement,
}: HiperPlayWelcomeProps) => {
  const { programs } = dropdownFields;

  const { handleSubmit, control } = useForm<HiperPlayFormInputs>();

  const onSubmit: SubmitHandler<HiperPlayFormInputs> = (data) => {
    startEngagement(data);
  };

  return (
    <main className={styles.hiperPlayContent}>
      <p className={styles.hiperPlayInfoText}>
        Select a Program and click Start to begin an engagement.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="program"
          control={control}
          rules={{ required: 'Program is required' }}
          render={({ field, fieldState }) => (
            <Dropdown
              id={field.name}
              value={field.value}
              optionLabel="name"
              onChange={(e) => field.onChange(e.value)}
              focusInputRef={field.ref}
              options={programs}
              placeholder="Select a Program"
              style={{ maxWidth: '80%' }}
              className={classNames({ 'p-invalid': fieldState.error })}
            />
          )}
        />
        <button className={styles.hiperPlayStartBtn} type="submit">
          Start
        </button>
      </form>
    </main>
  );
};

export { HiperPlayWelcome };
