import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { api } from '../../services/api';
import styles from './TableauDashboard.module.scss';
import { useCurrentUserTenantId } from '../../hooks/queries/MyTeam';

type analyticsPage = 'INSIGHTS' | 'COMPLIANCE' | 'SUPEX';

interface InsightsTableauProps {
  renderDashboard: analyticsPage;
}

type AnalyticsConfig = {
  url: string;
  token: string;
};

const TableauDashboard = ({ renderDashboard }: InsightsTableauProps) => {
  const [vizURL, setVizURL] = useState<string>();
  const [token, setToken] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const tenantId = useCurrentUserTenantId() as number;

  function deferObservation(observer: ResizeObserver) {
    setTimeout(() => {
      const nav = document.getElementById('navbar');
      if (nav) observer.observe(nav);
    }, 1000);
  }

  const getPageSrc = async (
    tenantId: number,
    page: analyticsPage
  ): Promise<AnalyticsConfig> => {
    return (
      await api.get<AnalyticsConfig>(`/${tenantId}/api/analytics?page=${page}`)
    ).data;
  };

  useEffect(() => {
    if (tenantId) {
      getPageSrc(tenantId, renderDashboard).then((config) => {
        setVizURL(config.url);
        setToken(config.token);
      });
    }
  }, [renderDashboard, tenantId]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect.width > 60) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      });
    });
    if (typeof window !== 'undefined') {
      const nav = document.getElementById('navbar');
      if (nav) {
        observer.observe(nav);
      } else {
        // this handles when the page was refreshed; the element lookup returned null, so give the browser some time
        // and then register the resizeObserver on navbar.
        deferObservation(observer);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  function localizedTableauSDK() {
    const sdkResourcePath = 'javascripts/api/tableau.embedding.3.latest.min.js';
    const eu = `https://prod-uk-a.online.tableau.com/${sdkResourcePath}`;
    const us_east = `https://prod-useast-a.online.tableau.com/${sdkResourcePath}`;
    return vizURL?.includes('uk') ? eu : us_east;
  }

  return (
    <>
      <Helmet>
        <script type="module" src={localizedTableauSDK()} async />
      </Helmet>
      <section
        className={
          isOpen ? styles.vizContainer : styles.vizContainerNavMinimized
        }
      >
        {vizURL && (
          // Tableau does not provide types
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          <tableau-viz
            id="tableauViz"
            src={vizURL}
            toolbar="hidden"
            device="desktop"
            token={token}
            hide-tabs={true}
          />
        )}
      </section>
    </>
  );
};

export default TableauDashboard;
