/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef, Ref } from 'react';
import {
  DataTable as PrimeReactDataTable,
  DataTableFilterEvent,
  DataTablePageEvent,
  DataTableProps as PrimeReactDataTableProps,
  DataTableSelectAllChangeEvent,
  DataTableSelectionCellChangeEvent,
  DataTableSelectionMultipleChangeEvent,
  DataTableSelectionSingleChangeEvent,
  DataTableSortEvent,
  DataTableStateEvent,
  DataTableValueArray,
} from 'primereact/datatable';

type DataTableType = {
  paginatorTemplate?: any;
} & PrimeReactDataTableProps<DataTableValueArray>;

// eslint-disable-next-line react/display-name
const DataTable = forwardRef((props: DataTableType, ref: Ref<any>) => (
  <PrimeReactDataTable {...props} ref={ref} />
));

export { DataTable };
export type {
  DataTableFilterEvent,
  DataTablePageEvent,
  DataTableSelectAllChangeEvent,
  DataTableSelectionCellChangeEvent,
  DataTableSelectionMultipleChangeEvent,
  DataTableSelectionSingleChangeEvent,
  DataTableSortEvent,
  DataTableStateEvent,
  DataTableType,
  DataTableValueArray,
};
