import { useCallback, useState } from 'react';
import {
  Paginator as PrimeReactPaginator,
  PaginatorTemplateOptions,
  PaginatorCurrentPageReportOptions,
  PaginatorPageChangeEvent,
  PaginatorProps as PrimeReactPaginatorProps,
  PaginatorFirstPageLinkOptions,
  PaginatorJumpToPageInputOptions,
  PaginatorLastPageLinkOptions,
  PaginatorNextPageLinkOptions,
  PaginatorPageLinksOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
} from 'primereact/paginator';

interface PaginatorProps
  extends Omit<PrimeReactPaginatorProps, 'onPageChange'> {
  paginatorCallback: (props: PaginatorPageChangeEvent) => void;
}

const defaultTemplate: PaginatorTemplateOptions = {
  layout:
    'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',

  CurrentPageReport: ({ first, last }: PaginatorCurrentPageReportOptions) => (
    <span>
      {first} to {last}
    </span>
  ),
  FirstPageLink: null,
  PrevPageLink: null,
  PageLinks: null,
  NextPageLink: null,
  LastPageLink: null,
  JumpToPageInput: null,
  RowsPerPageDropdown: null,
};

const defaultRowsPerPageOptions = [10, 20, 30, 40, 50];

const Paginator = ({
  rowsPerPageOptions = defaultRowsPerPageOptions,
  first: firstElement = 0,
  rows: firstRow = rowsPerPageOptions[0],
  template = defaultTemplate,
  paginatorCallback,
  ...props
}: PaginatorProps) => {
  const [first, setFirst] = useState(firstElement);
  const [rows, setRows] = useState(firstRow);

  const handlePageChange = useCallback(
    (event: PaginatorPageChangeEvent) => {
      setFirst(event.first);
      setRows(event.rows);
      paginatorCallback(event);
    },
    [paginatorCallback]
  );

  return (
    <PrimeReactPaginator
      {...props}
      first={first}
      rows={rows}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={handlePageChange}
      template={template}
    />
  );
};

export { Paginator };
export type {
  PaginatorProps,
  PaginatorPageChangeEvent,
  PaginatorTemplateOptions,
  PaginatorCurrentPageReportOptions,
  PaginatorFirstPageLinkOptions,
  PaginatorPrevPageLinkOptions,
  PaginatorPageLinksOptions,
  PaginatorNextPageLinkOptions,
  PaginatorLastPageLinkOptions,
  PaginatorRowsPerPageDropdownOptions,
  PaginatorJumpToPageInputOptions,
};
