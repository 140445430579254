import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { PaperPlane } from './PaperPlane';

import '../../components/EngagementDetail/ScrollPanelStyle.css';

export default function EngagementSummarySectionMock({
  isLoading,
}: Readonly<{ isLoading: boolean }>) {
  const [showStatus, setShowStatus] = useState(false);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center w-full p-5 gap-y-2">
        <FontAwesomeIcon
          icon={faSpinner}
          className="text-colorGray animate-spin"
          size="2x"
        />
        <p>Loading summary</p>
      </div>
    );
  }

  return (
    <div className="h-full py-4 pl-6 pr-8 overflow-auto text-colorBlack custombar">
      <h4 className="text-lg font-bold text-colorBlack">
        Call Summary & Post Call Notes
      </h4>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Reason for call</h5>
        <p className="text-xs">
          <span className="font-bold">Intent: </span>
          <i>Detected from call direction Metadata</i>
        </p>
        <p className="text-xs">
          <span className="font-bold">Topic(s): </span>
          <span className="font-bold bg-[#f6f4ff] text-[#491efb] p-[3px] rounded-sm">
            Inbound
          </span>
        </p>
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Customer Details</h5>
        <p className="text-xs">
          <span className="font-bold">Name: </span>
          <span className="font-bold bg-[#f6f4ff] text-[#4d5e70] p-[3px] rounded-sm">
            [Redacted]
          </span>
        </p>
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Summary of call</h5>
        <span>
          Visitor was interested to learn of extra benefits.{' '}
          <span className="font-bold text-[#ff196e]">
            Visitor confirmed their plan information: name, date of birth,
            address
          </span>{' '}
          and{' '}
          <span className="font-bold text-[#ff196e]">
            {' '}
            Medicare card number
          </span>
          . Visitor confirmed having both Medicare and Medicaid. Agent confirmed
          Health Net is current plan and shared plan benefits. Benefits
          discussed included:{' '}
          <span className="font-bold text-[#4e23fb]">dental</span> and{' '}
          <span className="font-bold text-[#4e23fb]">
            over-the-counter benefits.
          </span>{' '}
          Agent confirmed visitor&apos;s primary doctor accepts current plan.
          Agent confirmed doctor is listed as primary care provider. Visitor
          shared details of their residential location in the mountains. Agent
          and visitor discussed today&apos;s weather. Agent shared regulatory
          information. Visitor agreed to regulatory information and enrollment
          was submitted. Agent{' '}
          <span className="font-bold text-[#36cea0]">
            confirmed the enrollment application was submitted and shared
            contact information for follow up
          </span>
          .{' '}
          <span className="font-bold text-[#ffb218]">
            Agent asked visitor to refer any family or friends who may need
            assistance. Visitor expressed appreciation. Agent expressed
            appreciation. Call was ended.
          </span>
        </span>
        <div>
          <button
            type="button"
            className="bg-[#5019ff] text-white px-4 py-1 border-none rounded-md mt-8 flex items-center gap-2"
            onClick={() => setShowStatus(true)}
          >
            Send to CRM <PaperPlane />
          </button>
        </div>
        {showStatus && (
          <p className="mt-8">
            <strong>Status</strong>{' '}
            <span>
              Shared to CRM <i>10:50 AM</i>
            </span>
          </p>
        )}
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Outcome of call</h5>
        <div className="flex gap-2 item-center">
          <i className="text-lg text-[#36cea0] pi pi-check-circle" />
          <span className="font-bold text-[#36cea0]">Enrolled in Plan</span>
        </div>
      </section>
      <section className="py-2 mb-1 border-b">
        <h5 className="text-sm font-bold">Call notes</h5>
        <p className="text-xs">Call notes not available yet.</p>
      </section>
    </div>
  );
}
