import { useQuery } from '@tanstack/react-query';
import { api } from '../../services/api';

export function useGetCurrentUser() {
  return useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const response = await api.get<User>('/api/oauth/whoami');
      if (!response?.data) {
        throw new Error('Failed to retrieve user data');
      }
      api.defaults.headers.common[
        'tenant'
      ] = `${response?.data.attributes.tenantPublicId[0]}`;
      return response.data;
    },
    retry: 1,
  });
}
