import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Controller, useForm } from 'react-hook-form';
import { PaperPlane } from '../../services/demo/PaperPlane';

interface FeedbackSectionProps {
  agent?: AgentData;
}

type FeedbackFormValues = {
  feedback: string;
};

export default function FeedbackSection({
  agent,
}: Readonly<FeedbackSectionProps>) {
  const { control, handleSubmit, reset } = useForm<FeedbackFormValues>({
    defaultValues: { feedback: '' },
  });

  const onSubmit = () => {
    // Implement -> Submit form values to BE and show alert message
    reset();
  };

  return (
    <div className="w-6/12 pl-8">
      <h2 className="text-sm font-bold text-colorGray">Feedback Loop</h2>
      <h4 className="mt-1 text-xs text-colorGray">
        {`Send ${agent?.firstName} feedback on this call's performance.`}
      </h4>
      <form
        className="flex flex-col items-end"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="feedback"
          control={control}
          render={({ field }) => (
            <InputTextarea
              className="w-full pb-12 text-xs border-2 border-colorBlue text-colorGray"
              autoResize
              rows={3}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />
        <Button
          type="submit"
          className="flex gap-2 px-4 py-1 mr-2 -mt-10 text-white border-none rounded-md bg-colorBlue"
        >
          Send
          <PaperPlane />
        </Button>
      </form>
    </div>
  );
}
