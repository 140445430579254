import { SelectButton, SelectButtonChangeEvent } from 'primereact/selectbutton';
import { Dispatch, SetStateAction, useCallback } from 'react';

type SelectOption = { icon: string; value: boolean };

const justifyOptions: SelectOption[] = [
  { icon: 'pi pi-th-large', value: true },
  { icon: 'pi pi-list', value: false },
];

const justifyTemplate = (option: SelectOption) => {
  return <i className={option.icon}></i>;
};

interface SelectMyTeamViewModeProps {
  changeView: Dispatch<SetStateAction<boolean>>;
}

export default function SelectMyTeamViewMode({
  changeView,
}: Readonly<SelectMyTeamViewModeProps>) {
  const handleChange = useCallback(
    (e: SelectButtonChangeEvent) => {
      window.sessionStorage.setItem('myTeamGridView', e.target.value);
      changeView(e.target.value);
    },
    [changeView]
  );

  return (
    <div className="flex justify-end justify-content-center">
      <SelectButton
        onChange={handleChange}
        itemTemplate={justifyTemplate}
        optionLabel="value"
        options={justifyOptions}
      />
    </div>
  );
}
