import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { api } from '../services/api';

interface SupervisorUserDetails {
  id: number;
  fullName: string;
  firstName: string;
  lastName: string;
  tenantPublicId: number | undefined;
  authorities: string[];
}

const anonSupervisor: SupervisorUserDetails = {
  id: 0,
  fullName: 'Anon User',
  firstName: 'Anon',
  lastName: 'User',
  tenantPublicId: undefined,
  authorities: [],
};

type Children = { children: ReactNode };
const SupervisorUserDetailsContext =
  createContext<SupervisorUserDetails>(anonSupervisor);

export const useSupervisorUserDetails = () =>
  useContext(SupervisorUserDetailsContext);

export const SupervisorUserDetailsProvider = (children: Children) => {
  const [currentSupervisor, setCurrentSupervisor] =
    useState<SupervisorUserDetails>(anonSupervisor);

  const getSupervisorDetails = async () => {
    const response = await api.get<SupervisorUserDetails>(
      '/api/platform/supervisor-details'
    );
    if (!response?.data) {
      setCurrentSupervisor(anonSupervisor);
    }
    setCurrentSupervisor(response.data);
    window.sessionStorage.setItem(
      'supervisor-details',
      JSON.stringify(response.data)
    );
  };

  useEffect(() => {
    const supervisorDetails =
      window.sessionStorage.getItem('supervisor-details');
    if (supervisorDetails) {
      setCurrentSupervisor(JSON.parse(supervisorDetails));
      return;
    }
    void getSupervisorDetails();
  }, []);

  return (
    <SupervisorUserDetailsContext.Provider
      {...children}
      value={currentSupervisor}
    />
  );
};
