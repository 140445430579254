import { ReactNode } from 'react';
import {
  Dialog as PrimeReactDialog,
  DialogProps as PrimeReactDialogProps,
} from 'primereact/dialog';

import { Button, ButtonProps } from 'primereact/button';
import styles from './Dialog.module.scss';

type FooterProps = {
  displayFooter?: boolean;
  displayConfirmButton?: boolean;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  confirmButtonProps?: ButtonProps;
  confirmationButtonText?: string;
  displayCancelButton?: boolean;
  cancelButtonProps?: ButtonProps;
  cancelButtonText?: string;
};

type DialogProps = PrimeReactDialogProps & {
  cancelButtonProps?: ButtonProps;
  confirmButtonProps?: ButtonProps;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  displayCancelButton?: boolean;
  displayConfirmButton?: boolean;
  displayFooter?: boolean;
  displayCloseIcon?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

type HeaderProps = {
  header?: ReactNode | ((props: DialogProps) => ReactNode);
};

const Header = ({ header }: HeaderProps) => (header ? <>{header}</> : null);

const Footer = ({
  cancelButtonText,
  displayFooter,
  displayConfirmButton,
  displayCancelButton,
  onConfirmClick,
  onCancelClick,
  confirmButtonProps,
  cancelButtonProps,
  confirmationButtonText,
}: FooterProps) =>
  displayFooter ? (
    <div className="flex flex-row flex-row-reverse">
      {displayConfirmButton && (
        <Button
          severity="info"
          onClick={onConfirmClick}
          {...confirmButtonProps}
        >
          {confirmationButtonText}
        </Button>
      )}
      {displayCancelButton && (
        <Button
          severity="secondary"
          onClick={onCancelClick}
          {...cancelButtonProps}
        >
          {cancelButtonText}
        </Button>
      )}
    </div>
  ) : null;

const Dialog = ({
  cancelButtonText = 'Cancel',
  confirmationButtonText = 'Confirm',
  confirmButtonProps,
  cancelButtonProps,
  displayCancelButton = true,
  displayConfirmButton = true,
  displayFooter = true,
  displayCloseIcon = !displayFooter,
  header,
  children,
  visible,
  onHide,
  ...rest
}: DialogProps) => {
  const onCancelClick = () => {
    onHide();
  };

  const onConfirmClick = () => {
    onHide();
  };

  const getHeader = (props: HeaderProps) => <Header {...props} />;
  const getFooter = (props: FooterProps) => <Footer {...props} />;

  return (
    <PrimeReactDialog
      visible={visible}
      closable={displayCloseIcon}
      onHide={onHide}
      header={getHeader({ header })}
      footer={getFooter({
        displayFooter,
        displayConfirmButton,
        onConfirmClick,
        onCancelClick,
        confirmButtonProps,
        confirmationButtonText,
        displayCancelButton,
        cancelButtonProps,
        cancelButtonText,
      })}
      {...rest}
    >
      {children && <div className={styles.bodyDiv}>{children}</div>}
    </PrimeReactDialog>
  );
};

export { Dialog };
export type { DialogProps };
