import { ReactElement, useState } from 'react';
import { PanelMenu } from 'primereact/panelmenu';
import { Links } from './Links';
import { BACKEND_ROUTES } from '../../services/api';

import styles from './Navbar.module.scss';
import logo from '../../assets/logo.png';
import genie from '../../assets/genie.png';
import { LogoutIcon } from '../Icons';
import { getSessionStorageOrDefault } from '../../utils/misc';
import { useWhitelabel } from '../../context/WhitelabelContext';

type Whitelabel = {
  xsell: ReactElement;
  vmo2: ReactElement;
};

const whitelabel: Whitelabel = {
  xsell: (
    <img
      src={logo}
      width={22}
      height={20}
      alt="Supervisor Experience Logo"
      data-testid="product-logo"
    />
  ),
  vmo2: (
    <img
      src={genie}
      width={22}
      height={20}
      alt="Supervisor Experience Logo"
      className={styles.genieLogo}
    />
  ),
};

function HorizontalLine() {
  return (
    <div className={styles.collapseMenuContainer}>
      <hr />
    </div>
  );
}

export const Navbar = () => {
  const [dashboard, setDashboard] = useState(
    getSessionStorageOrDefault('dashboard', true)
  );
  const whitelabelData = useWhitelabel();

  const navLogo =
    whitelabelData.companyName === 'VMO2' ? whitelabel.vmo2 : whitelabel.xsell;

  const hideDashboard = () => {
    if (dashboard) {
      window.sessionStorage.setItem('dashboard', 'false');
      setDashboard(false);
    } else {
      window.sessionStorage.setItem('dashboard', 'true');
      setDashboard(true);
    }
  };

  const handleLogout = () => {
    window.location.replace(BACKEND_ROUTES.logout);
  };

  const logoutPanel = [
    {
      icon: <LogoutIcon />,
      label: dashboard ? 'Log Out' : '',
      command: handleLogout,
    },
  ];

  const piIcon = dashboard ? 'pi pi-chevron-left' : 'pi pi-chevron-right';

  return (
    <nav
      id="navbar"
      data-test-id="navbar"
      className={dashboard ? styles.navContainer : styles.navContainerMinimized}
    >
      <div className={styles.collapseMenuIcon}>
        <button type="button" onClick={hideDashboard}>
          <i className={`${piIcon}`} />
        </button>
      </div>
      <section
        className={dashboard ? styles.navHeader : styles.navHeaderMinimized}
      >
        <div>
          {navLogo}
          {dashboard && (
            <p className="text-2xl text-white font-semibold capitalize">
              Supervisor
            </p>
          )}
        </div>
      </section>
      <HorizontalLine />
      <section
        className={dashboard ? styles.navItems : styles.navItemsMinimized}
      >
        <Links open={dashboard ?? false} />
      </section>
      <HorizontalLine />
      <section className={styles.navFooter}>
        <PanelMenu model={logoutPanel} className={styles.signOutPanel} />
      </section>
    </nav>
  );
};
