import { PanelMenu } from 'primereact/panelmenu';

import styles from './Links.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CoachingIcon,
  ComplianceIcon,
  DashboardIcon,
  InsightsLensIcon,
  MyTeamIcon,
  SimulatorIcon,
} from '../Icons';
import { usePermissionContext } from '../../context/PermissionsProvider';

type NavbarLinksProps = {
  open: boolean;
};

export const Links = ({ open }: NavbarLinksProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isUserAuthorized } = usePermissionContext();

  const dashboardLink = {
    icon: <DashboardIcon className={styles.icons} />,
    label: open ? 'Dashboard' : '',
    command: () => {
      navigate('/');
    },
    routeBase: `/`,
  };

  const insightsLink = {
    icon: <InsightsLensIcon className={styles.icons} />,
    label: open ? 'Insights' : '',
    command: () => {
      navigate('/insights');
    },
    routeBase: '/insights',
  };

  const complianceLink = {
    icon: <ComplianceIcon className={styles.icons} />,
    label: open ? 'Compliance' : '',
    command: () => {
      navigate('/compliance');
    },
    routeBase: '/compliance',
  };

  const myTeamLink = {
    icon: <MyTeamIcon className={styles.icons} />,
    label: open ? 'My Team' : '',
    command: () => {
      navigate('/my-team');
    },
    routeBase: '/my-team',
  };

  const coachLink = {
    icon: <CoachingIcon className={styles.icons} />,
    label: open ? 'Coach' : '',
    command: () => {
      navigate('/hiper-coach');
    },
    routeBase: '/hiper-coach',
  };

  const simulatorLink = {
    icon: <SimulatorIcon className={styles.icons} />,
    label: open ? 'Simulator' : '',
    command: () => {
      navigate('/simulator');
    },
    routeBase: '/simulator',
  };

  const setLinkStyle = (linkRoute: string) => {
    return pathname === linkRoute ? styles.activePanel : styles.panel;
  };

  return (
    <>
      {isUserAuthorized('se-view_dashboard_tab') && (
        <PanelMenu
          model={[dashboardLink]}
          className={setLinkStyle(dashboardLink.routeBase)}
        />
      )}
      {isUserAuthorized('se-view_insights_tab') && (
        <PanelMenu
          model={[insightsLink]}
          className={setLinkStyle(insightsLink.routeBase)}
        />
      )}
      {isUserAuthorized('se-view_compliance_tab') && (
        <PanelMenu
          model={[complianceLink]}
          className={setLinkStyle(complianceLink.routeBase)}
        />
      )}
      {isUserAuthorized('se-view_team') && (
        <PanelMenu
          model={[myTeamLink]}
          className={setLinkStyle(myTeamLink.routeBase)}
        />
      )}
      {isUserAuthorized('se-view_coaching') && (
        <PanelMenu
          model={[coachLink]}
          className={setLinkStyle(coachLink.routeBase)}
        />
      )}
      {isUserAuthorized('se-view_play') && (
        <PanelMenu
          model={[simulatorLink]}
          className={setLinkStyle(simulatorLink.routeBase)}
        />
      )}
    </>
  );
};
