import Breadcrumbs from './Breadcrumbs';
import { Notifications } from '../modules/ui/Notifications';

const ContentLayoutHeader = () => {
  return (
    <div className="flex justify-between">
      <Breadcrumbs />
      <Notifications />
    </div>
  );
};

export default ContentLayoutHeader;
