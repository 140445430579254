import { Fragment, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import {
  faUser,
  faHeadset,
  faDesktop,
} from '@fortawesome/free-solid-svg-icons';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import type { WidgetProps } from '../TopicListWidget/widgetTypes';
import styles from './EngagementTranscriptWidget.module.scss';

const NONE_TOPIC = '"None"';

type EngagementDetailWithCustomCss = {
  customCss?: string;
};

export type Transcript = {
  from: 'agent' | 'customer' | 'event';
  timestamp: string;
  text: string;
  messageType?: 'system';
  autoScrollable?: boolean;
  topicPrediction?: string;
  eventId?: string;
};

type EngagementDetailBaseProps = {
  transcript: Transcript[];
  enableTooltip?: boolean;
  topics?: string[];
  onSelect?: (transcript: Transcript) => void;
  showTopicPrediction?: boolean;
};

type EngagementDetailWidgetProps = WidgetProps<
  EngagementDetailBaseProps,
  EngagementDetailWithCustomCss
>;

const getMessageTooltip = ({ from }: Transcript) => {
  if (from === 'event') {
    return 'System';
  }
  if (from === 'agent') {
    return 'Agent';
  }
  return 'Customer';
};

const getMessageIcon = ({ from }: Transcript) => {
  if (from === 'event') {
    return faDesktop;
  }
  if (from === 'agent') {
    return faHeadset;
  }

  return faUser;
};

const EngagementDetailWidget = ({
  className,
  data,
  isLoading,
}: EngagementDetailWidgetProps) => {
  const ref: any = useRef();

  useEffect(() => {
    if (ref.current)
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  });

  const { onSelect, showTopicPrediction } = data;
  const isSelectable = !!data.onSelect;

  const buildRowClassName = ({
    from,
    messageType,
    autoScrollable,
  }: Transcript): string => {
    const messageTypeClass =
      messageType === 'system' && from === 'agent'
        ? styles.agentSystemMessageRow
        : '';
    const fromAgentClass = from === 'agent' ? styles.agentRow : '';
    const fromEventClass = from === 'event' ? styles.eventRow : '';
    const fromCustomerClass = from === 'customer' ? styles.customerRow : '';
    const scrolledClass = autoScrollable ? styles.scrolledRow : '';
    const selectableClass = isSelectable ? styles.selectableRow : '';
    return `${messageTypeClass} ${fromAgentClass} ${fromEventClass} ${fromCustomerClass} ${scrolledClass} ${selectableClass}`;
  };

  return (
    <DataTable
      value={data.transcript}
      alwaysShowPaginator={false}
      dataKey="timestamp"
      emptyMessage="No transcript found."
      rowClassName={buildRowClassName}
      loading={isLoading}
      tableClassName={className}
      onRowClick={(e) => onSelect && onSelect(e.data as Transcript)}
    >
      <Column
        headerStyle={{ display: 'none' }}
        body={(message: Transcript) => (
          <span>{format(new Date(message.timestamp), 'hh:mm:ss aa')}</span>
        )}
        style={{
          whiteSpace: 'nowrap',
          width: '7rem',
          fontWeight: '500',
          fontSize: '0.875rem',
        }}
      />
      <Column
        headerStyle={{ display: 'none' }}
        body={(message: Transcript, { rowIndex }) =>
          rowIndex === 0 ||
          data.transcript[rowIndex - 1].from !== message.from ||
          data.transcript[rowIndex - 1].messageType !== message.messageType ? (
            <>
              <Tooltip position="top" target={`.icon-${rowIndex}`}>
                <span className={styles.toolTipTopic}>
                  {getMessageTooltip(message)}
                </span>
              </Tooltip>
              <FontAwesomeIcon
                icon={getMessageIcon(message)}
                width={16}
                className={`icon-${rowIndex}`}
                color="#21354C"
              />
            </>
          ) : null
        }
        style={{ minWidth: '1rem', padding: 0 }}
        align="center"
      />
      <Column
        headerStyle={{ display: 'none' }}
        body={(message: Transcript) => (
          <div>
            {message.text.split('**').map((fragment, index) =>
              index % 2 ? (
                <Fragment key={`${fragment}`}>
                  {data.enableTooltip && (
                    <Tooltip position="top" target={`.intent-${index}`}>
                      <p className={styles.toolTipTitle}>
                        Customer Intent detected
                      </p>
                      {data?.topics?.[Math.round(index / 2)] && (
                        <p className="tool-tip-title">
                          <span className={styles.toolTipTopic}>Topic:</span>{' '}
                          {data?.topics?.[Math.round(index / 2)]}
                        </p>
                      )}
                    </Tooltip>
                  )}
                  <span
                    id={index.toString()}
                    className={`intent-${index} ${styles.underlineText} p-1`}
                  >
                    {fragment}
                  </span>
                </Fragment>
              ) : (
                <span
                  className={`${message.messageType ?? ''} ${
                    message.from === 'event' ? styles.event : ''
                  }`}
                  key={`${fragment}`}
                  ref={message.autoScrollable ? ref : null}
                >
                  {fragment}
                </span>
              )
            )}
          </div>
        )}
        style={{ minWidth: '4rem' }}
        align="left"
      />
      {showTopicPrediction && (
        <Column
          headerStyle={{ display: 'none' }}
          body={(message: Transcript) => {
            const { topicPrediction } = message;
            const hasTopic = topicPrediction && topicPrediction !== NONE_TOPIC;

            return hasTopic ? (
              <div className="topic-bubble">{topicPrediction}</div>
            ) : null;
          }}
          align="right"
        />
      )}
    </DataTable>
  );
};

export { EngagementDetailWidget };
export type { EngagementDetailWidgetProps };
