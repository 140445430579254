interface ConversationChecklistCellProps {
  id: string;
  serieId: string;
  value: number | null;
  data: {
    x: string | number;
    y?: number | null;
    startDate?: string | null;
  };
  x: number;
  y: number;
  width: number;
  height: number;
  formattedValue: string | null;
  opacity: number;
  label: string;
  color: string;
  borderColor: string;
  labelTextColor: string;
}

const HeatMapTooltip = ({ cell }: { cell: ConversationChecklistCellProps }) => (
  <div className="flex items-center gap-1 px-2 py-1 whitespace-pre bg-white rounded-sm shadow-sm">
    <span
      className="w-4 h-4 inline-block border-[#dedede] border"
      style={{
        backgroundColor: cell.color,
      }}
    />
    <strong>{cell.serieId} - </strong>
    <span>{cell.data.x}: </span>
    <span>{cell.formattedValue ?? '0%'}</span>
  </div>
);

export default HeatMapTooltip;
