import { memo } from 'react';
import TableauDashboard from '../components/TableauDashboard/TableauDashboard';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';

const ComplianceDashboard = () => {
  const { isUserAuthorized } = usePermissionContext();
  const hasViewComplianceDashboard = isUserAuthorized('se-view_compliance_tab');

  return (
    <>
      {hasViewComplianceDashboard ? (
        <TableauDashboard renderDashboard={'COMPLIANCE'} />
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
};

export default memo(ComplianceDashboard);
