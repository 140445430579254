import { HiperPlayScenarioProps } from '../../types';

import { HiperTextArea } from './HiperTextArea';

import styles from '../../hiper-play.module.scss';

const HiperPlayScenario = ({
  endEngagement,
  onMessage,
}: HiperPlayScenarioProps) => (
  <section className={styles.hiperPlayScenario}>
    <span className="title">Play out a scenario</span>
    <HiperTextArea from="Agent" submitAction={onMessage} />
    <HiperTextArea from="Visitor" submitAction={onMessage} />
    <button
      type="button"
      className={styles.hiperPlayScenarioBtn}
      onClick={endEngagement}
    >
      End
    </button>
  </section>
);

export { HiperPlayScenario };
