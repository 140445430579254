import { DataTable } from '../../modules/ui/Datatable';
import withToggleTab from '../CollapsibleTab/withToggleTab';

// Consider removing this.
const HighlightReel = () => {
  return (
    <DataTable
      value={undefined}
      alwaysShowPaginator={false}
      emptyMessage="No information found for Highlight Reel."
    />
  );
};

export default withToggleTab(HighlightReel);
