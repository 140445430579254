import {
  faSpinner,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { memo, ReactNode } from 'react';
import ErrorMessage from '../ErrorMessage';

interface GenericLoaderProps {
  isLoading: boolean;
  isError: boolean;
  noData: boolean;
}

const responses: Record<keyof GenericLoaderProps, ReactNode> = {
  isLoading: (
    <FontAwesomeIcon
      icon={faSpinner}
      className="text-colorGray animate-spin"
      size="2x"
    />
  ),
  isError: <ErrorMessage />,
  noData: (
    <div className="flex flex-col">
      <FontAwesomeIcon icon={faCircleExclamation} color="#888" size="2x" />
      <p className="text-sm font-medium text-colorGray">
        No data available for this widget
      </p>
    </div>
  ),
};

const getResponse = ({ isError, isLoading, noData }: GenericLoaderProps) => {
  if (isLoading) return responses.isLoading;
  if (isError) return responses.isError;
  if (noData) return responses.noData;

  return null;
};

const GenericLoader = (props: GenericLoaderProps) => {
  return (
    <div className="flex items-center justify-center h-[181px] py-14">
      {getResponse(props)}
    </div>
  );
};

export default memo(GenericLoader);
