import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  ReactNode,
} from 'react';

import { HiperChatMessage, HiperPlayContextProps } from '../types';

type Children = { children?: ReactNode };

const HiperPlayContext = createContext<HiperPlayContextProps | null>(null);

HiperPlayContext.displayName = 'HiperPlayContext';

const useHiperPlayContext = () => {
  const context = useContext(HiperPlayContext);

  if (!context) {
    throw new Error(
      'useHiperPlayContext hook must be used within the HiperPlayProvider.'
    );
  }

  return context;
};

const HiperPlayProviderWrapper = (children: Children) => {
  const [isActive, setIsActive] = useState(false);
  const [message, setMessage] = useState<HiperChatMessage[]>([]);

  const handleActive = useCallback(
    (active: boolean) => {
      setIsActive(active);

      if (!active && message.length) {
        setMessage([]);
      }
    },
    [message.length]
  );

  const pushMessage = (lastMessage: HiperChatMessage) => {
    setMessage((prev) => [...prev, lastMessage]);
  };

  const value: HiperPlayContextProps = useMemo(
    () => ({
      isActive,
      setIsActive: handleActive,
      messageList: message,
      pushMessage,
    }),
    [handleActive, isActive, message]
  );

  return <HiperPlayContext.Provider {...children} value={value} />;
};

const HiperPlayProvider = ({ children }: Children) => (
  <HiperPlayProviderWrapper>{children}</HiperPlayProviderWrapper>
);

export { useHiperPlayContext, HiperPlayProvider };
