// Helps determine whether the component is on a tableau page or not
import { useEffect, useRef } from 'react';

export const onTableauPage = (pathname: string) => {
  return (
    pathname === '/compliance' || pathname === '/insights' || pathname === '/'
  );
};

export const showBreadCrumbs = (pathname: string) => {
  return pathname === '/hiper-coach' || onTableauPage(pathname);
};

export const getSessionStorageOrDefault = (
  key: string,
  defaultValue: boolean
): boolean => {
  if (typeof window !== 'undefined') {
    const stored = window.sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored) as boolean;
  }
  return defaultValue;
};

type Timer = ReturnType<typeof setTimeout>;
type SomeFunction = (...args: any[]) => void;
/**
 *
 * @param func The original, non debounced function (You can pass any number of args to it)
 * @param delay The delay (in ms) for the function to return
 * @returns The debounced function, which will run only if the debounced function has not been called in the last (delay) ms
 */

export function useDebounce<Func extends SomeFunction>(
  func: Func,
  delay = 1000
) {
  const timer = useRef<Timer>();

  useEffect(() => {
    return () => {
      if (!timer.current) return;
      clearTimeout(timer.current);
    };
  }, []);

  return ((...args) => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer.current);
    timer.current = newTimer;
  }) as Func;
}

export function getInitialsFromSingleName(name: string) {
  const splitName = name?.split(' ');
  if (!name) return '';
  return getInitials(splitName[0], splitName[splitName.length - 1]);
}

export function getInitials(firstName: string, lastName: string) {
  return `${firstName?.charAt(0)?.toUpperCase()}${lastName
    ?.charAt(0)
    ?.toUpperCase()}`;
}
