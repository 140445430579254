import { Button } from 'primereact/button';
import { Menu, MenuProps as PrMenuProps } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useRef } from 'react';

import '../../styles/prBtnOverride.scss';

interface MenuProps extends Omit<PrMenuProps, 'model'> {
  selectedItem: string;
  model: MenuItem[];
}

export default function DropdownMenu({
  selectedItem,
  model,
  ...props
}: Readonly<MenuProps>) {
  const menuRef = useRef<Menu>(null);

  return (
    <nav className="flex card justify-content-center">
      <Menu
        model={model}
        popup
        ref={menuRef}
        id="popup_menu"
        pt={{
          action: {
            className: 'justify-end w-fit',
          },
          root: {
            className: 'w-fit',
          },
        }}
        {...props}
      />
      <Button
        label={selectedItem}
        icon={<i className="pi pi-angle-down" />}
        onClick={(event) => {
          if (menuRef.current) {
            menuRef.current.toggle(event);
          }
        }}
        aria-controls="popup_menu"
        aria-haspopup
        pt={{
          root: {
            className: 'flex flex-row-reverse gap-4',
          },
        }}
      />
    </nav>
  );
}
