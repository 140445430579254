import { Dialog } from '../../modules/ui/Dialog';

import { ElementType } from 'react';
import { ComputedCell, HeatMapDatum } from '@nivo/heatmap';

interface DialogProps<T> {
  handleClose(): void;
  data: T;
}

const getHeader = ({ serieId, y, data }: ComputedCell<HeatMapDatum>) => (
  <header className="text-colorBlack">
    <h3 className="inline text-xl font-medium">{serieId}&nbsp;</h3>
    <span>{data.x}</span>
    <div className="w-full mb-1 border-b" />
    <p className="text-sm font-normal text-colorLightGray">
      {y?.toFixed(1) ?? 0}% adherence to the checklist
    </p>
  </header>
);

const withDialog = (WrappedComponent: ElementType) => {
  const WithDialog = <T extends object>({
    handleClose,
    data,
    ...rest
  }: DialogProps<ComputedCell<HeatMapDatum>> & T) => {
    return (
      <Dialog
        header={getHeader(data)}
        displayCancelButton={false}
        displayConfirmButton={false}
        footer={null}
        modal={false}
        onHide={handleClose}
        displayCloseIcon
        closeOnEscape
        visible
        showHeader
      >
        <WrappedComponent
          {...data}
          {...(rest as T)}
          handleClose={handleClose}
        />
      </Dialog>
    );
  };

  return WithDialog;
};

export default withDialog;
