import { useLocation } from 'react-router-dom';
import { useAlertMessage } from '../context/MessageProvider';
import AlertMessage from './AlertMessage/AlertMessage';
import { Navbar } from './Navbar/Navbar';
import { onTableauPage } from '../utils/misc';
import { ReactNode } from 'react';

interface ContentLayoutProps {
  children: ReactNode[];
}

export default function ContentLayout({
  children,
}: Readonly<ContentLayoutProps>) {
  const { message, severity, onClear } = useAlertMessage();
  const { pathname } = useLocation();

  return (
    <main className={'w-full transition-all duration-500 h-screen flex'}>
      <Navbar />
      {onTableauPage(pathname) ? (
        <>{children}</>
      ) : (
        <div className={'p-10 pr-0 w-full overflow-hidden'}>{children}</div>
      )}

      <div style={{ position: 'absolute', bottom: 5, right: 20 }}>
        <AlertMessage
          detail={message}
          severity={severity}
          handleClear={onClear}
        />
      </div>
    </main>
  );
}
