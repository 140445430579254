import SelectedCoachingPlanGraph, {
  ProgressProps,
} from './SelectedCoachingPlanGraph';
import { useEffect, useState } from 'react';
import {
  HiperCoachComparisonInterval,
  hiperCoachDropdownOptions,
} from '../ComparisonInterval';
import { useCurrentUserTenantId } from '../../../hooks/queries/MyTeam';
import {
  getBehavioralUsage,
  Progress,
  RecType,
} from '../../../services/behavioralUsageService';
import DropdownMenu from '../../DropdownMenu';
import { ActiveCoachingPlan } from '../../../services/coachingPlanService';
import styles from './SelectedCoachingPlanPanel.module.scss';

interface SelectedCoachingPlanPanelProps {
  cp: ActiveCoachingPlan;
}

interface FocusProgressChart {
  name: string;
  active: ProgressProps;
  historic: ProgressProps;
}

export default function SelectedCoachingPlanPanel({
  cp,
}: Readonly<SelectedCoachingPlanPanelProps>) {
  const [comparisonInterval, setComparisonInterval] =
    useState<HiperCoachComparisonInterval>(hiperCoachDropdownOptions[0]);
  const [focusProgressCharts, setFocusProgressCharts] = useState<
    FocusProgressChart[]
  >([]);

  const tenantId = useCurrentUserTenantId();
  const startDate = new Date(cp.startDate).toLocaleDateString('en-US', {
    timeZone: 'UTC',
  });
  const endDate = new Date(cp.endDate).toLocaleDateString('en-US', {
    timeZone: 'UTC',
  });

  function buildProgress(source: Progress | undefined) {
    const completionDetected = source?.heardCount ?? 0;
    const manuallyCompleted = source?.manualCount ?? 0;
    const seen = source?.incompleteCount ?? 0;
    const missed = source?.unseenCount ?? 0;
    const target: ProgressProps = {
      manuallyCompleted: manuallyCompleted,
      completionDetected: completionDetected,
      seen: seen,
      missed: missed,
    };
    return target;
  }

  useEffect(() => {
    if (cp.agentExternalId && tenantId && comparisonInterval) {
      getBehavioralUsage(
        tenantId,
        cp.agentExternalId,
        comparisonInterval.code
      ).then((behavioralUsage) => {
        const recTypes: RecType[] = behavioralUsage?.rec_types.filter(
          (rec_type) => {
            if (cp.behavioralRecommendations.includes(rec_type.name))
              return rec_type;
          }
        );
        const selectedCpCharts: FocusProgressChart[] = [];
        recTypes.map((recType) => {
          const activeProgress = buildProgress(recType.active);
          const historicProgress = buildProgress(recType.historic);
          selectedCpCharts.push({
            name: recType.name,
            active: activeProgress,
            historic: historicProgress,
          });
        });
        setFocusProgressCharts(selectedCpCharts);
      });
    }
  }, [
    tenantId,
    cp.agentExternalId,
    cp.behavioralRecommendations,
    comparisonInterval,
  ]);

  const items = [
    {
      visible: comparisonInterval?.code !== hiperCoachDropdownOptions[0].code,
      label: hiperCoachDropdownOptions[0].name,
      command() {
        setComparisonInterval(hiperCoachDropdownOptions[0]);
      },
    },
    {
      visible: comparisonInterval?.code !== hiperCoachDropdownOptions[1].code,
      label: hiperCoachDropdownOptions[1].name,
      command() {
        setComparisonInterval(hiperCoachDropdownOptions[1]);
      },
    },
    {
      visible: comparisonInterval?.code !== hiperCoachDropdownOptions[2].code,
      label: hiperCoachDropdownOptions[2].name,
      command() {
        setComparisonInterval(hiperCoachDropdownOptions[2]);
      },
    },
  ];

  const behavioralRecommendations = () => {
    return cp.behavioralRecommendations.toString().split(',').join(', ');
  };

  return (
    <div className="flex flex-col justify-start h-[460px] p-5 bg-white border gap-y-5 rounded-2xl text-colorGray overflow-y-auto">
      <h3 className="text-[20px] font-light">{cp.agentName}</h3>
      <div className="flex flex-col text-lg gap-y-2">
        <span className="text-xl font-semibold">Type</span>
        <span>{`Focus - ${behavioralRecommendations()}`}</span>
      </div>
      <div className="flex flex-col text-lg gap-y-2">
        <span className="text-xl font-semibold">Timeframe</span>
        <span>
          {startDate} - {endDate}
        </span>
      </div>
      <div className="flex flex-col gap-y-0.5 text-lg ">
        <span className="mb-2 text-xl font-semibold">Progress</span>
        <span>
          Completed:{' '}
          <span className="font-semibold">{cp.completed ?? '?'}</span>
        </span>
        <span>
          Not Used:{' '}
          <span className="font-semibold">{cp.total - cp.completed}</span>
        </span>
        <span>
          Recommendation seen: <span className="font-semibold">{cp.total}</span>
        </span>
      </div>
      <div className="flex flex-row justify-between pr-1 compare-progression-dropdown">
        <h3 className="text-xl font-semibold">Focus Progression</h3>
        <DropdownMenu model={items} selectedItem={comparisonInterval?.name} />
      </div>
      <div className="flex flex-col">
        {focusProgressCharts.map((focusProgressChart, index) => (
          <div
            key={`${focusProgressChart.name + index}`}
            className={styles.fpcContainer}
          >
            <h3>{focusProgressChart.name}</h3>
            <div className="graph-container">
              <SelectedCoachingPlanGraph
                key={`${focusProgressChart.name + index}`}
                active={focusProgressChart.active}
                historic={focusProgressChart.historic}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
