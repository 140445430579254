import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { ElementType, useState } from 'react';
import { cn } from '../../utils';

interface TabProps {
  title: string;
  iconFillColor?: string;
  OpenIcon?: IconDefinition;
  ClosedIcon?: IconDefinition;
  expanded?: boolean;
  titleClassName?: string;
  backgroundClassName?: string;
  disabled?: boolean;
}

interface IconProps {
  icon: IconDefinition;
  color: string;
}

const Icon = (props: IconProps) => <FontAwesomeIcon {...props} />;

const withToggleTab = <T extends object>(WrappedComponent: ElementType) => {
  const WithToggleTab = ({
    title,
    OpenIcon = faPlus,
    ClosedIcon = faMinus,
    iconFillColor = '#4d5d70',
    expanded = false,
    titleClassName = 'text-lg font-bold text-colorGray',
    backgroundClassName = 'bg-white',
    disabled,
    ...props
  }: TabProps & T) => {
    const [isOpen, setIsOpen] = useState(expanded);

    const toggleTab = () => {
      !disabled && setIsOpen(!isOpen);
    };

    return (
      <div className={cn('w-full', backgroundClassName)}>
        <div
          onClick={toggleTab}
          className={
            'px-4 cursor-pointer flex justify-between items-center min-h-[3rem]'
          }
        >
          <span className={titleClassName}>{title}</span>
          <span>
            <Icon
              color={disabled ? '#a0acbb' : iconFillColor}
              icon={isOpen ? ClosedIcon : OpenIcon}
            />
          </span>
        </div>
        {isOpen && (
          <div className="p-4 mt-1">
            <WrappedComponent {...(props as T)} />
          </div>
        )}
      </div>
    );
  };

  return WithToggleTab;
};

export default withToggleTab;
