import { WidgetTitle } from '../../modules/ui/WidgetTitle';
import { ElementType, useEffect, useState } from 'react';
import { ExpandIcon } from '../ExpandCollapseIcon/expand-collapse-icon';
import clsx from 'clsx';

interface WidgetCardTitleProps {
  title: string;
  titleInfoText?: string;
  expansible?: boolean;
}

interface ExpandProps {
  isExpanded: boolean;
  setExpandWidget(): void;
  expansible: boolean;
}

const Title = ({
  title,
  titleInfoText,
  expansible,
  setExpandWidget,
}: WidgetCardTitleProps & Partial<ExpandProps>) => {
  return (
    <WidgetTitle
      className="text-colorBlack"
      title={title}
      infoText={titleInfoText}
    >
      {expansible && setExpandWidget && (
        <button
          type="button"
          className={'ml-auto cursor-pointer'}
          onClick={() => expansible && setExpandWidget()}
        >
          <ExpandIcon />
        </button>
      )}
    </WidgetTitle>
  );
};

interface WidgetCardProps {
  className?: string;
  expansible?: boolean;
  defaultExpanded?: boolean;
}

const withWidgetCard = <T extends object>(
  WrappedComponent: ElementType,
  title: string,
  titleInfoText: string
) => {
  const WithWidgetCard = ({
    expansible = false,
    defaultExpanded = false,
    className = 'p-4 overflow-hidden bg-white border-[#f2f3f3] rounded-md drop-shadow-none',
    ...props
  }: WidgetCardProps & T) => {
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      setIsExpanded(defaultExpanded);
    }, [defaultExpanded]);

    const setExpandWidget = () => {
      setIsExpanded((prev) => !prev);
    };

    const expandProps = {
      isExpanded,
      expansible,
      setExpandWidget,
    };

    return (
      <div className={clsx(className, { 'col-span-full': isExpanded })}>
        <Title
          title={title}
          titleInfoText={titleInfoText}
          expansible={expansible}
          setExpandWidget={setExpandWidget}
        />
        <WrappedComponent
          {...(props as T)}
          {...(expansible ? (expandProps as ExpandProps) : {})}
        />
      </div>
    );
  };

  WithWidgetCard.displayName = 'WithWidgetCardHOC';
  return WithWidgetCard;
};

export default withWidgetCard;
