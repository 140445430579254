import { memo, useMemo } from 'react';
import { cn } from '../../utils';
import { getInitials } from '../../utils/misc';

type AvatarProps = {
  agentId: string;
  firstName: string;
  lastName: string;
};

export function getAgentColor(agent: string) {
  const colors = ['#FFB624', '#22F5B5', '#7F1DFB', '#1D4EFB', '#FF176B'];
  let hash = 0;

  if (agent.length === 0) return colors[hash];
  for (let i = 0; i < agent.length; i++) {
    hash = agent.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  hash = ((hash % colors.length) + colors.length) % colors.length;

  return colors[hash];
}

const Avatar = ({ agentId, firstName, lastName }: AvatarProps) => {
  const themeColor = useMemo(() => {
    return getAgentColor(agentId + firstName + lastName);
  }, [agentId, firstName, lastName]);

  return (
    <div
      className={cn(
        'rounded-full flex justify-center items-center w-28 h-28',
        !themeColor && 'bg-emerald-400'
      )}
      style={{
        backgroundColor: themeColor,
      }}
    >
      <div className="flex items-center justify-center w-full h-full text-5xl font-medium text-white">
        {getInitials(firstName, lastName)}
      </div>
    </div>
  );
};

export default memo(Avatar);
