import { ReactNode } from 'react';
import { Agent } from '../../../services/agent-ranking-service';

export default function AgentCard({
  agent,
  position,
  active,
  callback,
  children,
}: Readonly<{
  agent: Agent;
  position: number;
  active: boolean;
  callback: (arg: string) => void;
  children?: ReactNode;
}>) {
  return (
    <div
      className={`flex flex-row justify-start items-center space-x-4 w-full h-[46px] rounded-lg px-4 pl-3 text-colorGray bg-${
        active ? 'white' : 'transparent'
      }`}
      onClick={() => {
        callback(agent.agentId);
      }}
      onKeyDown={() => {
        callback(agent.agentId);
      }}
    >
      <span className="text-sm mr-2 w-[4px]">{position}</span>
      {children}
      <span className="font-semibold capitalize">
        {agent?.name?.toLowerCase() || agent.agentId}
      </span>
    </div>
  );
}
