import { memo } from 'react';
import TableauDashboard from '../components/TableauDashboard/TableauDashboard';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';

const InsightsDashboard = () => {
  const { isUserAuthorized } = usePermissionContext();
  const hasViewInsightsDashboard = isUserAuthorized('se-view_insights_tab');

  return (
    <>
      {hasViewInsightsDashboard ? (
        <TableauDashboard renderDashboard={'INSIGHTS'} />
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
};

export default memo(InsightsDashboard);
