import { api } from './api';
import { ComparisonInterval } from '../components/HiperCoach/ComparisonInterval';

export type RecType = {
  name: string;
  active: Progress;
  historic: Progress;
};

export type Progress = {
  unseenCount: number;
  incompleteCount: number;
  manualCount: number;
  heardCount: number;
};

export type BehavioralUsageMetadata = {
  spName: string;
  tenantPublicId: number;
  userId: string;
  comparisonInterval: ComparisonInterval;
  version: string;
};

export type BehavioralUsage = {
  metadata: BehavioralUsageMetadata;
  rec_types: RecType[];
};

export async function getBehavioralUsage(
  tenantPublicId: number | undefined,
  agentExternalId: string,
  comparisonInterval: ComparisonInterval
): Promise<BehavioralUsage> {
  let apiEndpoint = `${tenantPublicId}/api/coaching/behavioral-usage/${agentExternalId}`;
  const params = new URLSearchParams();
  if (comparisonInterval) {
    params.append('comparisonInterval', comparisonInterval);
  }
  apiEndpoint += `?${params.toString()}`;

  const response = await api.get<BehavioralUsage>(apiEndpoint);
  return response.data;
}
