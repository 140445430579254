import { Chart } from 'primereact/chart';
import { restructureBehaviorDataForGraph } from './RestructureBehaviorDataForGraph';
import { BehavioralUsage } from '../../../services/behavioralUsageService';
import { ChartOptions, LegendItem } from 'chart.js';

interface BehavioralUsageGraphProps {
  data?: BehavioralUsage;
}

const textColor = '#4D5D70';
const surfaceBorder = '#D9D9D9';

const chartOptions: ChartOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.8,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'end',
      labels: {
        font: { size: 12, family: 'Poppins', weight: 400 },
        usePointStyle: true,
        boxHeight: 9,
        color: textColor,
        filter: ({ datasetIndex = 0 }: LegendItem) =>
          ![0, 2, 4, 6].includes(datasetIndex),
      },
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: textColor,
        font: {
          size: 12,
          family: 'Poppins',
          weight: 400,
        },
      },
      grid: {
        display: false,
      },
      border: { display: false },
    },
    y: {
      stacked: true,
      ticks: {
        stepSize: 0.25,
        callback: (_, index, ticks) => ticks[index].value * 100 + '%',
        font: {
          size: 12,
          family: 'Poppins',
          weight: 400,
        },
      },
      grid: {
        color: surfaceBorder,
        tickWidth: 0,
      },
      border: {
        dash: [2, 4],
        display: false,
      },
    },
  },
};

export default function BehavioralUsageGraph({
  data,
}: Readonly<BehavioralUsageGraphProps>) {
  const chartData = restructureBehaviorDataForGraph(data);

  return (
    <div className="card">
      <Chart
        type="bar"
        className="pt-10 pb-5 pl-10 pr-12 h-[254px]"
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
}
