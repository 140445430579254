import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { clsx } from 'clsx';
import styles from '../CoachPageUtils.module.scss';
import { useCurrentUserTenantId } from '../../../hooks/queries/MyTeam';
import { getRecTypes } from '../../../services/rec-types-service';
import { getFilteredAgents, getProgramList } from '../../../services/service';
import { Dropdown } from 'primereact/dropdown';
import {
  ActiveCoachingPlanParams,
  createActiveCoachingPlan,
} from '../../../services/coachingPlanService';
import { useSupervisorUserDetails } from '../../../context/SupervisorDetailsProvider';
import { getSessionStorageOrDefault } from '../../../utils/misc';

interface IRecType {
  id: number;
  name: string;
}

interface IProgram {
  id: string;
  name: string;
  description: string;
  tenantPublicId: number;
}

interface AssociatedAgent {
  agentExternalId: string;
  directSupervisor: object;
  firstName: string;
  lastName: string;
  fullName: string;
  id: number;
  login: string;
  program: object;
  startDate: string;
}

interface IFormInput {
  planType: string;
  selectedRecTypes: IRecType[] | null;
  selectedProgram: IProgram | null;
  selectedAgents: AssociatedAgent[] | null;
  dates: string[] | null;
}

export default function NewCoachingPlanDialog({
  visible,
  onClose,
}: Readonly<{
  visible: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}>) {
  const [recTypes, setRecTypes] = useState([]);
  const [programs, setPrograms] = useState<AgentProgram[]>();
  const [agents, setAgents] = useState<AssociatedAgent[]>();
  const [assignType, setAssignType] = useState<
    'All' | 'Program' | 'Individual'
  >('All');
  const tenantId = useCurrentUserTenantId();
  const supervisorDetails = useSupervisorUserDetails();

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
    clearErrors,
  } = useForm({
    defaultValues: {
      planType: 'Focus',
      selectedRecTypes: null,
      selectedProgram: null,
      selectedAgents: null,
      dates: null,
    },
  });

  const resetForm = () => {
    setAssignType('All');
    reset();
    clearErrors();
    onClose(false);
  };

  useEffect(() => {
    getRecTypes(tenantId).then((data) =>
      setRecTypes(data.recommendation_types)
    );
    getProgramList(tenantId).then((data) => {
      setPrograms(data.content);
    });
    const filter = `&directSupervisorId.equals=${supervisorDetails.id}`;
    getFilteredAgents(filter, 20, 0).then((res) => {
      setAgents(res.content);
    });
  }, [supervisorDetails.id, tenantId]);

  // Center the modal within the coaching plan viewport, instead of the entire pages viewport
  const centerModal = () => {
    const NAV_OPEN = '200px';
    const NAV_CLOSED = '60px';
    const dashboardState = getSessionStorageOrDefault('dashboard', true);
    return dashboardState ? NAV_OPEN : NAV_CLOSED;
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!data.dates || data.dates.length < 2) {
      return;
    }

    if (!data.selectedRecTypes || data.selectedRecTypes.length < 1) {
      return;
    }

    // Backend requires UTC and PrimeReact calendar doesn't support timezones (yet)
    const timeFix = 'T00:00:00.000Z';

    data.dates = data.dates.map((date) => {
      return new Date(date).toISOString().split('T')[0].concat(timeFix);
    });

    const payload: ActiveCoachingPlanParams = {
      startDate: data.dates[0],
      endDate: data.dates[1],
      behavioralRecommendations: [],
      type: data.planType.toUpperCase(),
      programId: null,
      agentIds: [],
    };

    data.selectedRecTypes.forEach((recType) => {
      payload.behavioralRecommendations.push(recType.name);
    });

    if (assignType === 'Program' && data?.selectedProgram) {
      const name = data.selectedProgram.name;
      const program = programs?.find((p) => {
        return p?.name?.toLowerCase() === name?.toLowerCase();
      });
      if (program) {
        payload['programId'] = program.id;
      }
    }
    if (assignType === 'Individual' && data.selectedAgents) {
      const agentIds: number[] = [];
      agents?.forEach((agent) => {
        data.selectedAgents?.forEach((selectedAgent) => {
          if (agent.id === selectedAgent.id) {
            agentIds.push(selectedAgent.id);
          }
        });
      });

      if (agentIds?.length) {
        payload['agentIds'] = agentIds;
      }
    }

    createActiveCoachingPlan(payload).then(() => {
      resetForm();
    });
  };
  return (
    <Dialog
      visible={visible}
      closable
      onHide={() => {
        if (!visible) return;
        resetForm();
      }}
      content={() => (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex flex-col bg-white w-[850px] h-full p-7 rounded-lg space-y-9 text-lg',
              styles.coachFont
            )}
            style={{ marginLeft: centerModal(), borderRadius: '16px' }}
          >
            <div className="flex flex-row justify-between items-center">
              <h3 className="text-[20px] font-medium">
                Create a Coaching Plan
              </h3>
              <Button
                unstyled={true}
                icon="pi pi-times"
                className={styles.modalXBtn}
                onClick={() => {
                  resetForm();
                }}
              />
            </div>
            <div className="flex flex-col">
              <h4 className="text-[16px] mb-8 font-semibold">
                Coaching Plan Type
              </h4>
              <Controller
                name="planType"
                control={control}
                render={() => {
                  return (
                    <div className="flex">
                      <RadioButton
                        inputId="planType"
                        className="mt-0.5"
                        value="Focus"
                        checked
                      />
                      <label htmlFor="planType" className="ml-2 text-[14px]">
                        Focus
                      </label>
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <span className="text-[14px]">
                Select the behavioral recommendation type(s) to include
              </span>
              <Controller
                name="selectedRecTypes"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <div className="card flex justify-content-center">
                      <MultiSelect
                        options={recTypes}
                        optionLabel="name"
                        inputRef={ref}
                        pt={{
                          token: {
                            className:
                              'bg-[#f7f4ff] text-[#4A1DFB] text-lg rounded-md',
                          },
                        }}
                        display="chip"
                        className="w-[266px] border"
                        showSelectAll={false}
                        showClear={false}
                        {...field}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h4 className="text-[16px] font-semibold mb-4">Timeframe</h4>
              <span className="-mt-4 text-[14px]">
                Select a date range to activate this plan{' '}
              </span>
              <Controller
                name="dates"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="card flex justify-content-center mt-6">
                      <Calendar
                        {...field}
                        selectionMode="range"
                        readOnlyInput
                        required
                        hideOnRangeSelection
                        showIcon
                        minDate={new Date()}
                        dateFormat={'mm/dd/y'}
                        icon={() => (
                          <i className="pi pi-calendar text-xl mb-0.5" />
                        )}
                        iconPos="left"
                        className={styles.calendar}
                        pt={{
                          input: {
                            root: {
                              className:
                                'create-plan-calendar font-medium text-colorGray h-[32px] mb-0.5',
                            },
                          },
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <div className="flex flex-col">
              <h4 className="text-[16px] font-semibold mb-4">Assign</h4>
              <span className="-mt-4 mb-4 text-[14px]">
                Select the agent(s) you want to assign this coaching plan to
              </span>

              <div className="flex justify-between text-[14px] mt-4 whitespace-nowrap">
                <div className="flex">
                  <RadioButton
                    inputId="assignAllAgents"
                    value="All"
                    onChange={(e) => setAssignType(e.value)}
                    checked={assignType === 'All'}
                  />
                  <label htmlFor="planType" className="ml-2">
                    Assign to all agents
                  </label>
                </div>
                <div className="flex mr-48">
                  <RadioButton
                    inputId="assignByProgram"
                    value="Program"
                    className="ml-28"
                    onChange={(e) => setAssignType(e.value)}
                    checked={assignType === 'Program'}
                  />
                  <label htmlFor="planType" className="pl-2">
                    Assign by Program
                  </label>
                </div>
                <div className="flex">
                  <RadioButton
                    inputId="assignByIndividual"
                    value="Individual"
                    onChange={(e) => setAssignType(e.value)}
                    checked={assignType === 'Individual'}
                  />
                  <label htmlFor="planType" className="mr-20 pl-2">
                    Assign by individual agents
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-end  w-full ">
              <Controller
                name="selectedProgram"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="card flex flex-col justify-content-center mr-10">
                      <span className="-mb-0.5 text-xl">Program</span>
                      <Dropdown
                        {...field}
                        options={programs}
                        optionLabel="name"
                        className="w-[260px] border"
                        showClear={false}
                        disabled={assignType !== 'Program'}
                      />
                    </div>
                  );
                }}
              />
              <Controller
                name="selectedAgents"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="card flex flex-col justify-content-center">
                      <span className="-mb-0.5 text-[14px]">Agent</span>
                      <MultiSelect
                        {...field}
                        options={agents}
                        optionLabel="fullName"
                        display="chip"
                        className="w-[260px] border"
                        pt={{
                          token: {
                            className:
                              'bg-[#f7f4ff] text-[#4A1DFB] text-lg rounded-md',
                          },
                        }}
                        showSelectAll={false}
                        showClear={false}
                        disabled={assignType !== 'Individual'}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <hr />
            <div className="flex justify-end w-full">
              <Button
                label="Cancel"
                className={'px-3 py-2 mr-6'}
                pt={{
                  label: { classNames: 'text-[14px] font-semibold' },
                }}
                onClick={() => {
                  if (!visible) return;
                  resetForm();
                }}
              />
              <Button
                label="Create Plan"
                className={'new-cp-button px-3 py-2 border-2 '}
                pt={{
                  label: { classNames: 'text-[14px] font-semibold text-white' },
                }}
                type="submit"
                disabled={!isValid}
              />
            </div>
          </div>
        </form>
      )}
    ></Dialog>
  );
}
