import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

type Children = { children?: ReactNode };

const MessageContext = createContext<MessageContextProps | null>(null);

MessageContext.displayName = 'AlertMessageContext';

export const useAlertMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error(
      'useMessage hook must be used within the AlertMessageContext.'
    );
  }

  return context;
};

const MessageProviderWrapper = (children: Children) => {
  const [message, setMessage] = useState<MessageProps>({
    detail: '',
    severity: 'success',
  });

  const value: MessageContextProps = useMemo(
    () => ({
      message: message.detail,
      severity: message.severity,
      setMessageData: (detail: string, severity: MessageSeverity) =>
        setMessage({
          detail,
          severity,
        }),
      onClear: () => setMessage({ detail: '', severity: 'success' }),
    }),
    [message.detail, message.severity]
  );

  return <MessageContext.Provider {...children} value={value} />;
};

const AlertMessageProvider = ({ children }: AlertMessageProps) => {
  return <MessageProviderWrapper>{children}</MessageProviderWrapper>;
};

export default AlertMessageProvider;
