import { useState } from 'react';
import styles from './CoachPageUtils.module.scss';
import { clsx } from 'clsx';
import { ActiveCoachingPlan } from '../../services/coachingPlanService';
import InsightsAndDataContainer from './InsightsAndDataPanel/InsightsAndDataContainer';
import ActiveCoachingPlansContainer from './ActiveCoachingPlansPanel/ActiveCoachingPlansContainer';

export default function HiperCoachContainer() {
  const [activeCoachingPlans, setActiveCoachingPlans] = useState<
    ActiveCoachingPlan[] | null
  >();

  return (
    <>
      <h2 className="text-[22px] font-semibold text-colorGray">
        Coaching Plans
      </h2>
      <div
        className={clsx(
          'flex flex-col w-full gap-y-2 h-full overflow-auto',
          styles.coachFont
        )}
      >
        <InsightsAndDataContainer
          setActiveCoachingPlans={setActiveCoachingPlans}
        />
        <ActiveCoachingPlansContainer
          activeCoachingPlans={activeCoachingPlans}
          setActiveCoachingPlans={setActiveCoachingPlans}
        />
      </div>
    </>
  );
}
