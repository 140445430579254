import { HeatMapScale } from '../../../modules/ui/HeatMapScale';
import { HeatMap } from '../../../modules/ui/HeatMap';
import { useState } from 'react';
import HeatMapTooltip from '../utils/HeatMapTooltip';
import InfoMessage from '../utils/InfoMessage';
import {
  heatMapColors,
  heatMapProps,
} from '../utils/conversationChecklistConstants';
import AgentProfileDialog from './AgentProfileDialog';

import { ComputedCell, HeatMapDatum } from '@nivo/heatmap';
import { DEMO_AGENT_EXTERNAL_ID } from '../../../services/demo/data';

interface AgentExternalId {
  agentExternalId: string;
}

interface ConversationChecklistPresentation extends AgentExternalId {
  data: ConversationChecklist[];
}

const ConversationChecklistPresentation = ({
  agentExternalId,
  data,
}: ConversationChecklistPresentation) => {
  const [modalData, setModalData] = useState<ComputedCell<HeatMapDatum> | null>(
    null
  );

  return (
    <>
      <HeatMap
        {...heatMapProps}
        tooltip={HeatMapTooltip}
        onClick={(cell) => {
          if (agentExternalId === DEMO_AGENT_EXTERNAL_ID) {
            if (cell.serieId === 'Motivation' && cell.value === 0) {
              cell.y = 5.2;
            } else {
              cell.y = 0;
            }
          }

          setModalData(cell);
        }}
        className="w-full h-72"
        data={data}
        margin={{ top: 40, right: 20, bottom: 20, left: 170 }}
      />
      <HeatMapScale className="mb-6 mr-4" colors={heatMapColors} />
      <InfoMessage text="Checklist usage for <strong>Motivation</strong> falls below the recommended usage. Click to view details." />
      {modalData && (
        <AgentProfileDialog<AgentExternalId>
          agentExternalId={agentExternalId}
          handleClose={() => setModalData(null)}
          data={modalData}
        />
      )}
    </>
  );
};

export default ConversationChecklistPresentation;
