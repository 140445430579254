import { ChartDataset } from 'chart.js';
import { BehavioralUsage } from '../../../services/behavioralUsageService';

const barOption = {
  borderWidth: 1,
  barPercentage: 1,
  maxBarThickness: 20,
};

export const restructureBehaviorDataForGraph = (
  behaviorData?: BehavioralUsage
) => {
  const labels: string[] = [];

  const datasets: ChartDataset<'bar'>[] = [
    {
      label: 'Completion Detected',
      backgroundColor: '#84E3C7',
      borderColor: '#84E3C7',
      stack: '0',
      data: [],
      ...barOption,
    },
    {
      label: 'Completion Detected',
      backgroundColor: '#09C88F',
      borderColor: '#09C88F',
      stack: '1',
      data: [],
      ...barOption,
    },
    {
      label: 'Manually Completed',
      backgroundColor: '#8BC7FF',
      borderColor: '#8BC7FF',
      stack: '0',
      data: [],
      ...barOption,
    },
    {
      label: 'Manually Completed',
      backgroundColor: '#1790FF',
      borderColor: '#1790FF',
      stack: '1',
      data: [],
      ...barOption,
    },
    {
      label: 'Seen',
      backgroundColor: '#ECECEC',
      borderColor: '#ECECEC',
      stack: '0',
      data: [],
      ...barOption,
    },
    {
      label: 'Seen',
      backgroundColor: '#D9D9D9',
      borderColor: '#D9D9D9',
      stack: '1',
      data: [],
      ...barOption,
    },
    {
      label: 'Unseen',
      backgroundColor: '#FFFFFF',
      borderColor: '#D9D9D9',
      stack: '0',
      data: [],
      ...barOption,
    },
    {
      label: 'Unseen',
      backgroundColor: '#FFFFFF',
      borderColor: '#D9D9D9',
      stack: '1',
      data: [],
      ...barOption,
    },
  ];
  behaviorData?.rec_types.forEach((segment) => {
    const { name, historic, active } = segment;
    labels.push(name);
    const historicTotal =
      historic.manualCount +
      historic.heardCount +
      historic.incompleteCount +
      historic.unseenCount;

    datasets[0].data.push(historic.heardCount / historicTotal);
    datasets[2].data.push(historic.manualCount / historicTotal);
    datasets[4].data.push(historic.incompleteCount / historicTotal);
    datasets[6].data.push(historic.unseenCount / historicTotal);

    const activeTotal =
      active.manualCount +
      active.heardCount +
      active.incompleteCount +
      active.unseenCount;

    datasets[1].data.push(active.heardCount / activeTotal);
    datasets[3].data.push(active.manualCount / activeTotal);
    datasets[5].data.push(active.incompleteCount / activeTotal);
    datasets[7].data.push(active.unseenCount / activeTotal);
  });

  return {
    labels,
    datasets: datasets.map((d) => ({
      ...d,
      categoryPercentage: labels.length / 10,
    })),
  };
};
