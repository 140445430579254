import { memo } from 'react';
import TableauDashboard from '../components/TableauDashboard/TableauDashboard';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';

const Dashboard = () => {
  const { isUserAuthorized } = usePermissionContext();
  const hasViewTableauDashboard = isUserAuthorized('se-view_dashboard_tab');

  return (
    <>
      {hasViewTableauDashboard ? (
        <TableauDashboard renderDashboard={'SUPEX'} />
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
};

export default memo(Dashboard);
