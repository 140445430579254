export enum ComparisonInterval {
  ONE_WEEK = 'ONE_WEEK',
  TWO_WEEKS = 'TWO_WEEKS',
  ONE_MONTH = 'ONE_MONTH',
}

export const ComparisonIntervalLabels: { [key in ComparisonInterval]: string } =
  {
    [ComparisonInterval.ONE_WEEK]: '1 Week Ago',
    [ComparisonInterval.TWO_WEEKS]: '2 Weeks Ago',
    [ComparisonInterval.ONE_MONTH]: '1 Month Ago',
  };

export type HiperCoachComparisonInterval = {
  name: string;
  code: ComparisonInterval;
};

export const hiperCoachDropdownOptions = [
  {
    name: ComparisonIntervalLabels[ComparisonInterval.ONE_WEEK],
    code: ComparisonInterval.ONE_WEEK,
  },
  {
    name: ComparisonIntervalLabels[ComparisonInterval.TWO_WEEKS],
    code: ComparisonInterval.TWO_WEEKS,
  },
  {
    name: ComparisonIntervalLabels[ComparisonInterval.ONE_MONTH],
    code: ComparisonInterval.ONE_MONTH,
  },
];
