import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

interface CoachingPlanListProps extends React.PropsWithChildren {
  readonly setNameFilter: (arg: string) => void;
  readonly onSortOrderChange: () => void;
}

export default function CoachingPlansList({
  setNameFilter,
  onSortOrderChange,
  children,
}: CoachingPlanListProps) {
  return (
    <div className="flex flex-col p-5 pb-0 bg-white border h-[460px] w-[442px] gap-y-3 rounded-3xl ">
      <div className="flex items-center justify-between text-colorGray">
        <span className="p-input-icon-left">
          <i className="pi pi-search pl-4" />
          <InputText
            className="active-coaching-plan-filter text-[10px] w-[220px] h-[26px] rounded-xl font-semibold border"
            onChange={(e) => {
              setNameFilter(e.target.value.toLowerCase());
            }}
            placeholder="Search agent name"
          />
        </span>
        <Button
          icon={<i className="pi pi-sort-alt text-gray-400" />}
          label="End Date"
          onClick={onSortOrderChange}
          pt={{
            root: {
              className:
                'flex flex-row-reverse gap-2 whitespace-nowrap min-w-fit text-[10px] ',
            },
            label: { className: 'font-normal text-gray-400' },
          }}
        />
      </div>
      <div className="flex flex-col min-w-[428px] pr-0 pb-5 -ml-1 gap-y-2.5 h-full overflow-y-auto overflow-x-hidden scroll-pr-0 ">
        {children}
      </div>
    </div>
  );
}
