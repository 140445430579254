import { api } from './api';

export type Agent = {
  agentId: string;
  name: string;
  rank: number;
};

type AgentRankMetadata = {
  version: string;
  tenantPublicId: number;
  spName: string;
  category: string;
  agentIds: string[];
};

export type RankedAgentsRes = {
  metadata: AgentRankMetadata;
  agents: Agent[];
};

export async function getRankedAgents(
  tenantPublicId: number | undefined,
  managerName?: string,
  agentExternalIds?: string[]
): Promise<RankedAgentsRes> {
  let apiEndpoint = `${tenantPublicId}/api/coaching/agent-ranks`;
  if (managerName || agentExternalIds) {
    const params = new URLSearchParams();
    if (managerName) {
      params.append('managerName', managerName);
    }
    if (agentExternalIds && agentExternalIds.length > 0) {
      params.append('agentIds', agentExternalIds.join(','));
    }
    apiEndpoint += `?${params.toString()}`;
  }

  const response = await api.get<RankedAgentsRes>(apiEndpoint);
  return response.data;
}
