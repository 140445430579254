import { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export interface ProgressProps {
  completionDetected: number;
  manuallyCompleted: number;
  seen: number;
  missed: number;
}

interface SelectedCoachingPlanGraphProps {
  active: ProgressProps;
  historic: ProgressProps;
}

const textColor = '#4D5D70';
const surfaceBorder = '#D9D9D9';

export default function SelectedCoachingPlanGraph({
  active,
  historic,
}: Readonly<SelectedCoachingPlanGraphProps>) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const activeTotal =
    active.missed +
    active.seen +
    active.completionDetected +
    active.manuallyCompleted;
  const historicTotal =
    historic.missed +
    historic.seen +
    historic.completionDetected +
    historic.manuallyCompleted;
  useEffect(() => {
    const data = {
      labels: ['Warm Greeting'],
      datasets: [
        {
          label: 'Completion Detected',
          backgroundColor: '#09C88F',
          borderColor: '#09C88F',
          data: [(active.completionDetected * 100) / activeTotal],
          stack: '0',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
          borderRadius: {
            topLeft: 3,
            bottomLeft: 3,
          },
          borderSkipped: false,
        },
        {
          label: 'Completion Detected',
          backgroundColor: '#84E3C7',
          borderColor: '#84E3C7',
          data: [(historic.completionDetected * 100) / historicTotal],
          stack: '1',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
          borderRadius: {
            topLeft: 3,
            bottomLeft: 3,
          },
          borderSkipped: false,
        },
        {
          label: 'Manually Completed',
          backgroundColor: '#1790FF',
          borderColor: '#1790FF',
          data: [(active.manuallyCompleted * 100) / activeTotal],
          stack: '0',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
        },
        {
          label: 'Manually Completed',
          backgroundColor: '#8BC7FF',
          borderColor: '#8BC7FF',
          data: [(historic.manuallyCompleted * 100) / historicTotal],
          stack: '1',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
        },
        {
          label: 'Seen',
          backgroundColor: '#D9D9D9',
          borderColor: '#D9D9D9',
          data: [(active.seen * 100) / activeTotal],
          stack: '0',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
        },
        {
          label: 'Seen',
          backgroundColor: '#ECECEC',
          borderColor: '#ECECEC',
          data: [(historic.seen * 100) / historicTotal],
          stack: '1',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderWidth: 0,
        },
        {
          label: 'Unseen',
          backgroundColor: '#FFFFFF',
          borderColor: '#D9D9D9',
          borderWidth: 1,
          data: [(active.missed * 100) / activeTotal],
          stack: '0',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderSkipped: 'start',
          borderRadius: {
            topRight: 3,
            bottomRight: 3,
          },
        },
        {
          label: 'Unseen',
          backgroundColor: '#FFFFFF',
          borderColor: '#D9D9D9',
          borderWidth: 1,
          data: [(historic.missed * 100) / historicTotal],
          stack: '1',
          barPercentage: 0.8,
          categoryPercentage: 0.8,
          borderSkipped: 'start',
          borderRadius: {
            topRight: 3,
            bottomRight: 3,
          },
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 1,
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          align: 'end',
          labels: {
            display: false,
            usePointStyle: true,
            fontColor: textColor,
            filter: function (item: any) {
              return ![0, 2, 4, 6].includes(item.datasetIndex);
            },
          },
          //eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick: () => {},
        },
        tooltip: {
          enabled: false,
        },
      },
      scales: {
        y: {
          stacked: true,
          ticks: {
            display: false,
            color: textColor,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
          border: { display: false },
        },
        x: {
          stacked: true,
          ticks: {
            display: false,
            min: 0,
            max: 100,
            stepSize: 0.25,
            callback: function (value: number) {
              return (value * 100).toFixed(0) + '%'; // convert it to percentage
            },
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
            tickWidth: 0,
            display: false,
          },
          border: {
            dash: [2, 4],
            display: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [active, historic]);

  return (
    <div className="card">
      <Chart
        type="bar"
        className="h-[70px]"
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
}
