import HiperCoachContainer from '../components/HiperCoach/HiperCoachContainer';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';

export default function HiperCoach() {
  const { isUserAuthorized } = usePermissionContext();
  const hasViewCoachingPermission = isUserAuthorized('se-view_coaching');
  return (
    <>
      {hasViewCoachingPermission ? (
        <HiperCoachContainer />
      ) : (
        <InvalidPermissions type="page" />
      )}
    </>
  );
}
