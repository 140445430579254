import { Dispatch, SetStateAction } from 'react';
import SelectMyTeamViewMode from '../SelectMyTeamViewMode';
import AgentDataTable from './AgentDataTable';

interface AgentsTableViewSectionProps {
  changeView: Dispatch<SetStateAction<boolean>>;
}

const AgentsTableViewSection = ({
  changeView,
}: AgentsTableViewSectionProps) => (
  <div className="flex flex-col w-full gap-6">
    <div className="flex flex-col gap-8">
      <SelectMyTeamViewMode changeView={changeView} />
      <h2 className="font-medium text-md text-slate-800">Agents List</h2>
    </div>
    <div className="p-4 bg-white rounded-md drop-shadow-lg">
      <AgentDataTable />
    </div>
  </div>
);

export default AgentsTableViewSection;
