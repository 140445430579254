import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Splitter, SplitterPanel } from 'primereact/splitter';

import { useParams, useSearchParams } from 'react-router-dom';
import {
  useGetAgentByLogin,
  useGetEngagementDetail,
  useGetEngagementSummary,
  useGetEngagementTranscript,
} from '../hooks/queries/MyTeam';
import EngagementTranscriptSection from '../components/EngagementDetail/engagement-transcript-section';
import EngagementAnalyticsSection from '../components/EngagementDetail/engagement-analytics-section';
import EngagementDetailsSection from '../components/EngagementDetail/engagement-details-section';
import EngagementSummarySection from '../components/EngagementDetail/engagement-summary-section';
import EngagementSummarySectionMock from '../services/demo/EngagementSummarySectionMock';
import { usePermissionContext } from '../context/PermissionsProvider';
import InvalidPermissions from '../components/InvalidPermissions/InvalidPermissions';
import { DEMO_AGENT_EXTERNAL_ID } from '../services/demo/data';

const Icons = {
  plus: <FontAwesomeIcon className="absolute right-4" icon={faPlus} />,
  minus: <FontAwesomeIcon className="absolute right-4" icon={faMinus} />,
};

export default function EngagementDetail() {
  const [searchParams] = useSearchParams();
  const { engagementId = '', agentId = '' } = useParams<{
    engagementId: string;
    agentId: string;
  }>();
  const { isLoading: isAgentLoading, data: agent } = useGetAgentByLogin(
    agentId || ''
  );

  const { data: transcript, isLoading: isTranscriptLoading } =
    useGetEngagementTranscript(
      engagementId,
      searchParams.get('startDate') || ''
    );
  const { data: detail, isLoading: isDetailLoading } = useGetEngagementDetail(
    engagementId,
    agent?.content[0].agentExternalId as string
  );

  const { data: summary, isLoading: isSummaryLoading } =
    useGetEngagementSummary(engagementId);

  const { getPermission } = usePermissionContext();
  const hasViewEngagementTranscriptPermission = getPermission(
    'se-view_engagement_transcripts'
  );
  const hasViewEngagementSummaryPermission = getPermission(
    'se-view_engagement_summary'
  );

  return (
    <div className="container py-7 flex items-baseline h-full justify-between flex-col gap-y-2.5 lg:flex-row lg:items-start lg:gap-7">
      <div className="w-full min-w-[560px] flex flex-col items-start gap-y-2 h-full">
        <Accordion
          className="w-full space-y-4"
          activeIndex={[0, 2]}
          expandIcon={Icons.plus}
          collapseIcon={Icons.minus}
          multiple
        >
          <AccordionTab
            header={
              <span className="text-lg font-bold bg-white text-colorBlack">
                Engagement Details
              </span>
            }
          >
            <EngagementDetailsSection
              detail={detail}
              agent={agent?.content[0]}
              isLoading={isDetailLoading || isAgentLoading}
            />
          </AccordionTab>
          <AccordionTab
            header={
              <span className="text-lg font-bold bg-white text-colorBlack">
                Engagement Analytics
              </span>
            }
          >
            <EngagementAnalyticsSection
              agent={agent?.content[0]}
              engagementId={engagementId as string}
            />
          </AccordionTab>
        </Accordion>
        <Splitter className="w-full h-full overflow-hidden">
          <SplitterPanel minSize={60} size={60}>
            {hasViewEngagementTranscriptPermission ? (
              <EngagementTranscriptSection
                isLoading={isTranscriptLoading}
                transcript={transcript}
              />
            ) : (
              <InvalidPermissions type="component" />
            )}
          </SplitterPanel>
          <SplitterPanel size={40} className="pl-1">
            {hasViewEngagementSummaryPermission ? (
              agent?.content[0].agentExternalId === DEMO_AGENT_EXTERNAL_ID ? (
                <EngagementSummarySectionMock isLoading={isSummaryLoading} />
              ) : (
                <EngagementSummarySection
                  isLoading={isSummaryLoading}
                  summary={summary}
                />
              )
            ) : (
              <InvalidPermissions type="component" />
            )}
          </SplitterPanel>
        </Splitter>
      </div>
    </div>
  );
}
