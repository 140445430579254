import { ProgressBar } from 'primereact/progressbar';
import clsx from 'clsx';
import { ActiveCoachingPlan } from '../../../services/coachingPlanService';

function extractDateRange(
  startDate: Date,
  endDate: Date
): [string, number, number] {
  const formatDateRange = (
    start: Date,
    end: Date
  ): [string, number, number] => {
    const options: Intl.DateTimeFormatOptions = { month: 'short' };
    const month = start.toLocaleDateString('en-US', options);
    const startDay = start.getUTCDate();
    const endDay = end.getUTCDate();
    return [month, startDay, endDay];
  };

  return formatDateRange(startDate, endDate);
}

export default function CoachingPlanCard({
  coachingPlan,
  selected,
  callback,
}: Readonly<{
  coachingPlan: ActiveCoachingPlan;
  position: number;
  selected: boolean;
  callback: (arg: ActiveCoachingPlan) => void;
}>) {
  const { total, completed, behavioralRecommendations, startDate, endDate } =
    coachingPlan;

  const start = new Date(startDate);
  const end = new Date(endDate);

  const [month, startDay, endDay] = extractDateRange(start, end);
  const focus = behavioralRecommendations.join(', ');

  return (
    <button
      type="button"
      className="flex flex-row justify-start items-center text-left min-w-[380px] h-[81px] rounded-lg text-colorGray bg-white cursor-pointer"
      onClick={() => {
        callback(coachingPlan);
      }}
    >
      <div className="flex flex-col text-center py-1.5 pb-2.5 w-[26px] h-full mr-2">
        <span className="font-sm">{month}</span>
        <span className="font-semibold">{startDay}</span>
        <span className="font-sm">-</span>
        <span className="font-semibold">{endDay}</span>
      </div>
      <div
        className={clsx(
          'flex flex-col py-2.5 space-y-2 px-3 border rounded-xl min-w-[380px] h-full',
          { ['bg-colorLightPurple']: selected }
        )}
      >
        <div className="text-[10px] font-medium text-white w-fit px-2.5 py-0.5 rounded-xl bg-amber-500 capitalize">{`${coachingPlan.type.toLowerCase()}: ${focus}`}</div>
        <span className="text-[14px] font-semibold">
          {coachingPlan.agentName}
        </span>

        <div className="flex flex-row flex-nowrap">
          <ProgressBar
            color="#605EEF"
            showValue={false}
            value={(completed / total) * 100}
            className="h-[10px] w-[325px] mr-2"
          />
          <span className="-mt-1 text-[10px] font-medium">{`${completed}/${total}`}</span>
        </div>
      </div>
    </button>
  );
}
