import BehavioralUsageGraph from './BehavioralUsageGraph';
import { ReactNode, useEffect, useState } from 'react';
import { useCurrentUserTenantId } from '../../../hooks/queries/MyTeam';
import { Agent } from '../../../services/agent-ranking-service';
import {
  BehavioralUsage,
  getBehavioralUsage,
} from '../../../services/behavioralUsageService';
import {
  HiperCoachComparisonInterval,
  hiperCoachDropdownOptions,
} from '../ComparisonInterval';
import DropdownMenu from '../../DropdownMenu';

export default function BehavioralUsagePanel({
  agent,
  children,
}: Readonly<{
  agent: Agent | undefined;
  children?: ReactNode;
}>) {
  const tenantId = useCurrentUserTenantId();
  const [progression, setProgression] = useState<HiperCoachComparisonInterval>(
    hiperCoachDropdownOptions[0]
  );
  const [chartData, setChartData] = useState<BehavioralUsage>();

  useEffect(() => {
    if (agent?.agentId && progression) {
      getBehavioralUsage(tenantId, agent.agentId, progression.code).then(
        setChartData
      );
    }
  }, [agent, progression, tenantId]);

  const items = [
    {
      visible: progression.code !== hiperCoachDropdownOptions[0].code,
      label: hiperCoachDropdownOptions[0].name,
      command() {
        setProgression(hiperCoachDropdownOptions[0]);
      },
    },
    {
      visible: progression.code !== hiperCoachDropdownOptions[1].code,
      label: hiperCoachDropdownOptions[1].name,
      command() {
        setProgression(hiperCoachDropdownOptions[1]);
      },
    },
    {
      visible: progression.code !== hiperCoachDropdownOptions[2].code,
      label: hiperCoachDropdownOptions[2].name,
      command() {
        setProgression(hiperCoachDropdownOptions[2]);
      },
    },
  ];

  return (
    <div className="flex flex-col justify-start w-full h-full p-5 pt-6 rounded-lg gap-y-7 text-colorGray">
      <div className="flex flex-row justify-between pr-1">
        <h3 className="text-[20px] font-light capitalize">
          {agent?.name?.toLowerCase() || agent?.agentId}
        </h3>
        {children}
      </div>
      <div className="flex flex-row items-center justify-between pr-1">
        <h3 className="text-xl font-semibold">Behavioral Usage</h3>
        <div className="flex items-center gap-2 compare-progression-dropdown">
          <label className="text-sm" htmlFor="progression-dropdown">
            Compare Progression:
          </label>
          <DropdownMenu model={items} selectedItem={progression.name} />
        </div>
      </div>

      <div className="h-[254px] overflow-hidden min-w-0 relative rounded-xl border graph-container">
        <BehavioralUsageGraph data={chartData} />
      </div>
    </div>
  );
}
