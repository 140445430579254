import styles from './HeatMapScale.module.scss';

const HeatMapScale = ({
  colors,
  marks = 5,
  className,
}: {
  colors: string[];
  marks?: number;
  className?: string;
}) => (
  <div className={className + ' ' + styles.container}>
    <p className={styles.label}>Scale</p>
    <div className={styles.scaleContainer}>
      <div
        className={styles.scale}
        style={{
          background: `linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%)`,
        }}
      />
      <div className={styles.marks}>
        {Array(marks)
          .fill(undefined)
          .map((_mark, index) => (
            <div className={styles.mark} key={_mark}>
              <div className={styles.markLine} />
              <span className={styles.markNumber}>
                {index * (100 / (marks - 1))}
              </span>
            </div>
          ))}
      </div>
    </div>
  </div>
);

export { HeatMapScale };
