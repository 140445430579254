import { api } from './api';
import { rowsPerPageOptions } from '../components/Agents/constants';

import {
  getAgentsByLoginMock,
  getEngagementTranscriptMock,
  getSingleEngagementDetailMock,
  isMockEngagement,
  getShuffledEngagementDetailsMock,
  DEMO_AGENT_EXTERNAL_ID,
} from './demo/data';

const contact_mode = 'both';

export async function getFilteredAgents(
  formattedFilters = '&firstName.contains=&sort=firstName,asc',
  pageSize = rowsPerPageOptions[0],
  pageNumber = 0
) {
  if (formattedFilters === '&login.equals=' + DEMO_AGENT_EXTERNAL_ID) {
    return getAgentsByLoginMock;
  }

  const response = await api.get<AgentSearchApiResponse>(
    `/api/platform/search?size=${pageSize}&page=${pageNumber}${formattedFilters}`
  );
  return response.data;
}

export function getAgentByLogin(login: string) {
  return getFilteredAgents('&login.equals=' + login, 1);
}

export async function addAgentToFavorites(
  tenantId: number,
  agentExternalId: string | number
) {
  const { data } = await api.get(
    `/api/platform/${tenantId}/supervisors/addFavorites`,
    { params: { agent_external_id: agentExternalId } }
  );

  return data;
}

export async function removeAgentFromFavorites(
  tenantId: number,
  agentExternalId: string | number
) {
  const { data } = await api.get(
    `/api/platform/${tenantId}/supervisors/removeFromFavorites`,
    { params: { agent_external_id: agentExternalId } }
  );

  return data;
}

export async function getAgentEngagementDetails(
  agentExternalId: string
): Promise<EngagementDetail[]> {
  if (agentExternalId === DEMO_AGENT_EXTERNAL_ID.toUpperCase()) {
    return getShuffledEngagementDetailsMock();
  }

  const response = await api.get<EngagementDetail[]>(
    `/supex/engagementDetails/`,
    {
      params: {
        agent_external_id: agentExternalId,
        contact_mode,
      },
    }
  );
  return response.data;
}

export async function getEngagementTranscript(
  engagementId: string,
  startDate: string
): Promise<EngagementTranscript[]> {
  if (isMockEngagement(engagementId)) {
    return getEngagementTranscriptMock;
  }

  const response = await api.get<
    { sender: string; timestamp: string; text: string }[]
  >(`/supex/engagementTranscript/`, {
    params: {
      engagement_id: engagementId,
      start_date: startDate,
      contact_mode,
    },
  });

  return response.data.map((transcript) => ({
    from: transcript.sender as EngagementTranscript['from'],
    text: transcript.text,
    timestamp: transcript.timestamp,
  }));
}

export async function getSingleEngagementDetail(
  engagementId: string,
  agentExternalId: string
) {
  if (isMockEngagement(engagementId)) {
    return getSingleEngagementDetailMock;
  }

  const response = await api.get<EngagementDetail>(
    `/supex/singleEngagementDetail/`,
    {
      params: {
        engagement_external_id: engagementId,
        agent_external_id: agentExternalId,
        contact_mode,
      },
    }
  );

  return response.data;
}

export async function getProgramList(tenantId: number | undefined) {
  const response = await api.get<AgentFetchResponse<AgentProgram>>(
    `/api/platform/programs`,
    {
      params: { tenantPublicId: tenantId },
    }
  );

  return response.data;
}

export async function getSupervisorList(tenantId: number | undefined) {
  const response = await api.get<AgentFetchResponse<AgentSupervisor>>(
    `/api/platform/supervisors`,
    {
      params: { tenantPublicId: tenantId },
    }
  );

  return response.data;
}

export async function getEngagementSummary(engagementId: string) {
  const response = await api.get<EngagementSummary>(
    `/supex/getSingleEngagementSummary/`,
    { params: { engagement_external_id: engagementId, contact_mode } }
  );

  return response.data;
}
