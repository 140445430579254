import { useEffect, useState } from 'react';
import TeamPerformancePanel from './TeamPerformancePanel';
import AgentCard from './AgentCard';
import BehavioralUsagePanel from './BehavioralUsagePanel';
import AgentBadge from './AgentBadge';
import {
  getRankedAgents,
  RankedAgentsRes,
} from '../../../services/agent-ranking-service';
import { useCurrentUserTenantId } from '../../../hooks/queries/MyTeam';
import { getInitialsFromSingleName } from '../../../utils/misc';
import { ActiveCoachingPlan } from '../../../services/coachingPlanService';
import NewCoachingPlanButton from './NewCoachingPlanButton';
import { useSupervisorUserDetails } from '../../../context/SupervisorDetailsProvider';
import { getFilteredAgents } from '../../../services/service';

const badgeColors: Array<string> = [
  'bg-colorPink',
  'bg-colorLime',
  'bg-colorBeige',
  'bg-colorLightOrange',
  'bg-colorPurple',
];

export default function InsightsAndDataContainer({
  setActiveCoachingPlans,
}: Readonly<{
  setActiveCoachingPlans: (arg: ActiveCoachingPlan[] | null) => void;
}>) {
  const [rankedAgents, setRankedAgents] = useState<RankedAgentsRes>();
  const [activeAgentId, setActiveAgentId] = useState<string>();

  const tenantId = useCurrentUserTenantId();
  const supervisorDetails = useSupervisorUserDetails();

  useEffect(() => {
    const filter = `&directSupervisorId.equals=${supervisorDetails.id}`;
    getFilteredAgents(filter, 20, 0).then((filteredAgentRes) => {
      const associatedAgentExternalIds = filteredAgentRes.content.map(
        (agent) => agent.agentExternalId
      );
      getRankedAgents(tenantId, undefined, associatedAgentExternalIds).then(
        (res) => {
          setRankedAgents(res);
          setActiveAgentId(res?.agents[0].agentId);
        }
      );
    });
  }, [tenantId, supervisorDetails.id]);

  let currentAgentIndex = 0;

  const getAgentBadgeColor = () => {
    const color = badgeColors[currentAgentIndex];
    currentAgentIndex++;

    if (currentAgentIndex > 4) currentAgentIndex = 0;
    return color;
  };

  const activeAgent = rankedAgents?.agents.find(
    (agent) => agent.agentId === activeAgentId
  );

  return (
    <>
      <section className="flex flex-col flex-1 gap-5 pr-10">
        <h3 className="text-[18px] font-light text-colorGray">
          Insights and Data
        </h3>
        <div className="flex flex-row gap-y-8 mb-5 border rounded-3xl border-[#E4E4E4] h-[460px] bg-white w-full overflow-y-none">
          <TeamPerformancePanel
            toggleSort={() => {
              setRankedAgents((prev) => {
                if (prev) {
                  return {
                    metadata: prev.metadata,
                    agents: [...prev.agents].reverse(),
                  };
                }
              });
            }}
          >
            {rankedAgents?.agents.map((agent) => {
              const agentInitials = agent?.name
                ? getInitialsFromSingleName(agent.name)
                : '?';
              return (
                <AgentCard
                  agent={agent}
                  position={agent.rank}
                  active={activeAgentId === agent.agentId}
                  key={agent.agentId}
                  callback={setActiveAgentId}
                >
                  <AgentBadge
                    agentInitials={agentInitials}
                    color={getAgentBadgeColor()}
                  />
                </AgentCard>
              );
            })}
          </TeamPerformancePanel>
          <BehavioralUsagePanel key={activeAgent?.agentId} agent={activeAgent}>
            <NewCoachingPlanButton
              setActiveCoachingPlans={setActiveCoachingPlans}
            />
          </BehavioralUsagePanel>
        </div>
      </section>
    </>
  );
}
