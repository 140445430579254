import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import NewCoachingPlanDialog from './NewCoachingPlanDialog';
import {
  ActiveCoachingPlan,
  getActiveCoachingPlan,
  sortCoachingPlansByEndDate,
} from '../../../services/coachingPlanService';

export default function NewCoachingPlanButton({
  setActiveCoachingPlans,
}: Readonly<{
  setActiveCoachingPlans: (arg: ActiveCoachingPlan[] | null) => void;
}>) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) return;
    getActiveCoachingPlan()
      .then(({ data }) => {
        if (data.length) {
          const sortedData = sortCoachingPlansByEndDate(data, 'asc');
          setActiveCoachingPlans(sortedData);

          return;
        }

        setActiveCoachingPlans(null);
      })
      .catch(() => {
        setActiveCoachingPlans(null);
      });
  }, [visible]);
  return (
    <>
      <Button
        label="New Coaching Plan"
        className={'new-cp-button text-white px-3 py-2 border-2'}
        onClick={() => setVisible(true)}
      />
      <NewCoachingPlanDialog visible={visible} onClose={setVisible} />
    </>
  );
}
