import { Outlet, useLocation } from 'react-router-dom';
import ContentLayout from './components/ContentLayout';
import ContentLayoutHeader from './components/ContentLayoutHeader';
import AgentListProvider from './context/AgentProvider';
import AlertMessageProvider from './context/MessageProvider';
import PermissionsProvider from './context/PermissionsProvider';
import { useAuth } from './hooks/useAuth';
import { showBreadCrumbs } from './utils/misc';
import { WhitelabelProvider } from './context/WhitelabelContext';
import { SupervisorUserDetailsProvider } from './context/SupervisorDetailsProvider';

export default function RootLayout() {
  useAuth();
  const { pathname } = useLocation();

  return (
    <PermissionsProvider>
      <AlertMessageProvider>
        <AgentListProvider>
          <WhitelabelProvider>
            <SupervisorUserDetailsProvider>
              <ContentLayout>
                {showBreadCrumbs(pathname) ? <></> : <ContentLayoutHeader />}
                <Outlet />
              </ContentLayout>
            </SupervisorUserDetailsProvider>
          </WhitelabelProvider>
        </AgentListProvider>
      </AlertMessageProvider>
    </PermissionsProvider>
  );
}
