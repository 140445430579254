import { memo, useCallback, useState } from 'react';
import { cn } from '../../utils';

enum AlertMessages {
  AGENT_ADDED = 'Agent has been added to your Watchlist',
  AGENT_REMOVED = 'Agent has been removed from your Watchlist',
  ADD_TOOLTIP = 'Add to Watchlist',
  REMOVE_TOOLTIP = 'Remove from Watchlist',
}

interface StarProps {
  isInWatchList: boolean;
  setAgentWatchList(
    willBeWatchListed: boolean,
    alertMessage: AlertMessageParams
  ): void;
}

const getAlertMessage = (willBeWatchListed: boolean): AlertMessageParams =>
  willBeWatchListed
    ? { message: AlertMessages.AGENT_ADDED, severity: 'success' }
    : { message: AlertMessages.AGENT_REMOVED, severity: 'success' };

const Star = ({ isInWatchList = false, setAgentWatchList }: StarProps) => {
  const [isEnabled, setIsEnabled] = useState(isInWatchList);

  const toggleState = useCallback(() => {
    setIsEnabled((prev) => {
      const willBeWatchListed = !prev;
      const alertMessage = getAlertMessage(willBeWatchListed);

      setAgentWatchList(willBeWatchListed, alertMessage);

      return willBeWatchListed;
    });
  }, [setAgentWatchList]);

  const iconStyle = isEnabled
    ? 'pi pi-star-fill text-yellow-300'
    : 'pi pi-star';

  return (
    <button
      type="button"
      onClick={toggleState}
      className={cn(iconStyle, 'cursor-pointer')}
      title={
        isInWatchList ? AlertMessages.REMOVE_TOOLTIP : AlertMessages.ADD_TOOLTIP
      }
    />
  );
};

export default memo(Star);
