import { useEffect, useState } from 'react';
import CoachingPlansList from '../InsightsAndDataPanel/CoachingPlansList';
import CoachingPlanCard from '../InsightsAndDataPanel/CoachingPlanCard';
import SelectedCoachingPlanPanel from './SelectedCoachingPlanPanel';
import {
  ActiveCoachingPlan,
  getActiveCoachingPlan,
  sortCoachingPlansByEndDate,
} from '../../../services/coachingPlanService';

interface ActiveCoachingPlansContainerProps {
  readonly activeCoachingPlans: ActiveCoachingPlan[] | null | undefined;
  readonly setActiveCoachingPlans: (arg: ActiveCoachingPlan[] | null) => void;
}

export default function ActiveCoachingPlansContainer({
  activeCoachingPlans,
  setActiveCoachingPlans,
}: ActiveCoachingPlansContainerProps) {
  const [selectedCoachingPlan, setSelectedCoachingPlan] =
    useState<ActiveCoachingPlan>();
  const [nameFilter, setNameFilter] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    getActiveCoachingPlan()
      .then(({ data }) => {
        if (data.length) {
          const sortedData = sortCoachingPlansByEndDate(data, 'asc');
          setActiveCoachingPlans(sortedData);
          setSelectedCoachingPlan(sortedData[0]);

          return;
        }

        setActiveCoachingPlans(null);
      })
      .catch(() => {
        setActiveCoachingPlans(null);
      });
  }, []);

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === 'desc' ? 'asc' : 'desc';
    setSortOrder(newSortOrder);
    if (activeCoachingPlans) {
      const sortedPlans = sortCoachingPlansByEndDate(
        activeCoachingPlans,
        newSortOrder
      );
      setActiveCoachingPlans([...sortedPlans]);
    }
  };

  if (typeof activeCoachingPlans === 'undefined') {
    return (
      <div className="flex items-center justify-center h-full">
        <i className="pi pi-spin pi-spinner" />
      </div>
    );
  }

  return (
    <section className="flex flex-col gap-3 w-full pr-10 min-h-[460px]">
      <h3 className="text-[18px] font-light text-colorGray">
        Active Coaching Plans
      </h3>
      <div className="grid gap-3 pb-10 grid-cols-coachingPlans">
        <CoachingPlansList
          setNameFilter={setNameFilter}
          onSortOrderChange={handleSortOrderChange}
        >
          {activeCoachingPlans?.length ? (
            activeCoachingPlans.map((coachingPlan, index) => {
              if (coachingPlan.agentName.toLowerCase().includes(nameFilter)) {
                return (
                  <CoachingPlanCard
                    coachingPlan={coachingPlan}
                    key={coachingPlan.id}
                    position={index + 1}
                    selected={selectedCoachingPlan?.id === coachingPlan.id}
                    callback={setSelectedCoachingPlan}
                  />
                );
              }
            })
          ) : (
            <p className="flex items-center justify-center h-full text-[14px] font-semibold pb-12">
              No active coaching plans created
            </p>
          )}
        </CoachingPlansList>

        {selectedCoachingPlan ? (
          <SelectedCoachingPlanPanel
            key={selectedCoachingPlan.id}
            cp={selectedCoachingPlan}
          />
        ) : (
          <div className="flex flex-col h-full p-5 overflow-auto bg-white border gap-y-3 rounded-3xl">
            <p className="flex items-center justify-center h-full text-[14px] font-semibold">
              Click “New Coaching Plan” above to create one
            </p>
          </div>
        )}
      </div>
    </section>
  );
}
