import { format, addDays } from 'date-fns';

const formatHeatMapData = (
  heatMapData: ConversationChecklist[],
  isSingleDate?: boolean,
  isExpanded?: boolean
) =>
  heatMapData.map(({ data, id }) => ({
    id,
    data: data?.map(({ x, y }) => {
      const date = new Date(x);
      const formattedDate = format(date, 'MM/dd');
      const addedDate = format(addDays(date, 6), 'MM/dd');

      const dateLabel =
        isSingleDate && !isExpanded
          ? `${formattedDate}`
          : `${formattedDate} - ${addedDate}`;

      return {
        x: dateLabel,
        y,
      };
    }),
  }));

export { formatHeatMapData };
