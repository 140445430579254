import { useState, KeyboardEvent, ChangeEvent } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';

import { HiperTextAreaProps } from '../../types';

import styles from '../../hiper-play.module.scss';

const HiperTextArea = ({ from, submitAction }: HiperTextAreaProps) => {
  const [message, setMessage] = useState('');

  const handleKeyUp = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.code === 'Enter' && !event.shiftKey && message.trim().length) {
      submitAction({ from, content: message.trim() });
      setMessage('');
    }
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  return (
    <div className={styles.hiperPlayTextArea}>
      <div>
        <span>{from}</span>
        <span>Press enter/return to send</span>
      </div>
      <InputTextarea
        rows={4}
        value={message}
        onKeyUp={handleKeyUp}
        onChange={handleChange}
      />
    </div>
  );
};

export { HiperTextArea };
