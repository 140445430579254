import { ChangeEvent, memo, useCallback } from 'react';
import { InputText } from 'primereact/inputtext';

interface WatchListSearchProps {
  inputActions: [string, (value: string) => void];
}

const WatchListSearch = ({ inputActions }: WatchListSearchProps) => {
  const [value, setValue] = inputActions;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return (
    <div className="flex gap-3">
      <InputText
        value={value}
        onChange={handleChange}
        placeholder="Search by name"
      />
    </div>
  );
};

export default memo(WatchListSearch);
