import { api } from './api';

export type RecType = {
  id: number;
  name: string;
};

type RecTypesMetadata = {
  day_interval: string;
  sp_name: string;
  tenant_public_id: number;
  version: string;
};

export type RecTypesRes = {
  metadata: RecTypesMetadata;
  agents: RecType[];
};

export async function getRecTypes(
  tenantPublicId: number | undefined
): Promise<any> {
  const apiEndpoint = `${tenantPublicId}/api/coaching/recommendation-types`;

  const response = await api.get<RecTypesRes>(apiEndpoint);
  return response.data;
}
