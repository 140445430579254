import { api } from './api';

export interface ActiveCoachingPlan {
  id: number;
  tenantPublicId: number;
  userId: number;
  agentId: number;
  programId: number;
  type: string;
  behavioralRecommendations: string[];
  startDate: string;
  endDate: string;
  total: number;
  completed: number;
  agentExternalId: string;
  agentName: string;
}

export interface ActiveCoachingPlanParams {
  type: string;
  programId: number | null;
  agentIds: number[];
  behavioralRecommendations: string[];
  startDate: string;
  endDate: string;
}

export const sortCoachingPlansByEndDate = (
  plans: ActiveCoachingPlan[],
  order: 'asc' | 'desc'
) => {
  return plans.sort((a, b) => {
    const dateA = new Date(a.endDate).getTime();
    const dateB = new Date(b.endDate).getTime();
    return order === 'asc' ? dateA - dateB : dateB - dateA;
  });
};

export async function getActiveCoachingPlan() {
  return await api.get<Array<ActiveCoachingPlan>>(
    '/api/platform/coaching?status=ACTIVE'
  );
}

export async function createActiveCoachingPlan(
  params: ActiveCoachingPlanParams
) {
  await api.post('/api/platform/coaching', params);
}
