import { createBrowserRouter, Link } from 'react-router-dom';
import HiperCoach from './pages/HiperCoach';
import MyTeam from './pages/MyTeam';
import RootLayout from './RootLayout';
import AgentProfile from './pages/agent-profile';
import ErrorMessage from './components/ErrorMessage';
import MyTeamContainer from './components/MyTeam/MyTeamContainer';
import EngagementDetail from './pages/engagement-detail';
import HiperPlay from './pages/HiperPlay';
import HiperPlayContainer from './components/HiperPlay/HiperPlayContainer';
import Dashboard from './pages/Dashboard';
import ComplianceDashboard from './pages/ComplianceDashboard';
import InsightsDashboard from './pages/InsightsDashboard';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
        errorElement: <ErrorMessage />,
      },
      {
        path: 'hiper-coach',
        element: <HiperCoach />,
        errorElement: <ErrorMessage />,
      },
      {
        path: '/my-team',
        handle: {
          crumb: () => (
            <Link
              key="my-team-link-crumb"
              to="/my-team"
              className="text-base font-medium text-colorBlue/80"
            >
              My Team
            </Link>
          ),
        },
        children: [
          {
            path: '',
            element: <MyTeam />,
            errorElement: (
              <MyTeamContainer>
                <ErrorMessage />
              </MyTeamContainer>
            ),
          },
          {
            path: 'agent/:agentId',
            handle: {
              crumb: (_data: unknown, params: Record<string, string>) => {
                return (
                  <Link
                    key="my-team-agent-link-crumb"
                    to={`/my-team/agent/${params.agentId}`}
                    className="text-base font-medium text-colorBlue/80"
                  >
                    Agent Profile
                  </Link>
                );
              },
            },
            children: [
              {
                path: '',
                element: <AgentProfile />,
                errorElement: <ErrorMessage />,
              },
              {
                path: 'engagements/:engagementId',
                element: <EngagementDetail />,
                errorElement: <ErrorMessage />,
                handle: {
                  crumb: (_data: unknown, params: Record<string, string>) => {
                    return (
                      <Link
                        to={`/my-team/agent/${params.agentId}/engagements/${params.engagementId}`}
                        className="text-base font-medium text-colorBlue/80"
                      >
                        Engagement
                      </Link>
                    );
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: 'simulator',
        handle: {
          crumb: () => (
            <Link
              key="simulator-link-crumb"
              to="/simulator"
              className="text-base font-medium text-colorBlue/80"
            >
              Simulator
            </Link>
          ),
        },
        element: <HiperPlay />,
        errorElement: (
          <HiperPlayContainer>
            <ErrorMessage />
          </HiperPlayContainer>
        ),
      },
      {
        path: 'insights',
        element: <InsightsDashboard />,
        errorElement: <ErrorMessage />,
      },
      {
        path: 'compliance',
        element: <ComplianceDashboard />,
        errorElement: <ErrorMessage />,
      },
    ],
  },
]);
