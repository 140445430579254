import { ReactNode } from 'react';

import { useHiperPlayContext } from '../context/HiperPlayContext';

import styles from '../hiper-play.module.scss';

interface HiperPlayEngagementWidgetProps {
  children?: ReactNode;
}

const HiperPlayEngagementWidget = ({
  children,
}: HiperPlayEngagementWidgetProps) => {
  const { isActive } = useHiperPlayContext();

  return (
    <div className={styles.hiperPlayWrapper}>
      {isActive && children ? (
        children
      ) : (
        <p className={styles.engagementText}>
          Start an engagement from the left to view HiPer.
        </p>
      )}
    </div>
  );
};

export { HiperPlayEngagementWidget };
