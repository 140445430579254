import { useQuery } from '@tanstack/react-query';
import { useGetCurrentUser } from '.';
import {
  getAgentRecommendations,
  getConversationChecklistForAgent,
  getConversationChecklistForEngagement,
} from '../../services/conversationChecklist';
import { format } from 'date-fns';

export function useGetConversationChecklistForAgent(agentExternalId: string) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  return useQuery({
    queryKey: ['conversationChecklistForAgent', agentExternalId],
    queryFn: () => getConversationChecklistForAgent(agentExternalId),
    enabled: !!tenantId,
  });
}

export function useGetConversationChecklistForEngagement(engagementId: string) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];

  return useQuery<ConversationChecklist[]>({
    queryKey: ['conversationChecklistForEngagement', engagementId],
    queryFn: () => getConversationChecklistForEngagement(engagementId),
    enabled: !!tenantId,
  });
}

export function useGetAgentRecommendations(
  weekBeginDate: Date,
  agentExternalId: string,
  recType: string
) {
  const { data: userData } = useGetCurrentUser();
  const tenantId = userData?.attributes.tenantPublicId[0];
  const formattedDate = format(weekBeginDate, 'yyyy-MM-dd');

  return useQuery({
    queryKey: [
      'conversationChecklist',
      'agent',
      agentExternalId,
      weekBeginDate,
      recType,
    ],
    queryFn: () =>
      getAgentRecommendations(agentExternalId, formattedDate, recType),
    enabled: !!tenantId,
  });
}
